import React, { useContext } from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { pathwaysDailyReflectionSubmit } from 'sdk';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { notifyError, notifySuccess } from 'utils/notifications';

import DailyReflectionDisplay from './components/DailyReflectionDisplay';
import DailyReflectionInput from './components/DailyReflectionInput';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const DailyReflection = ({
  dailyReflection,
  selectedDate,
  refetchDaiyReflection
}) => {
  const user = useContext(UserContext);

  const onSubmit = async ({ reflectionText, facialExpression }) => {
    if (!reflectionText) {
      notifyError('Please fill in your reflection for the day');
      return;
    }

    if (reflectionText?.length > 255) {
      notifyError(
        'The maximum length of the daily reflection is 255 characters'
      );
      return;
    }

    if (!facialExpression) {
      notifyError('Please select your mood for the day');
      return;
    }

    const { success } = await pathwaysDailyReflectionSubmit({
      data: {
        text: reflectionText,
        facial_expression: facialExpression,
        for_date: selectedDate
      }
    });

    if (success) {
      notifySuccess('Reflection created successfully');
      refetchDaiyReflection({ selectedDate });

      trackMixpanelEvent(user.id, 'User submits self reflection', {
        feature: 'pathways',
        view: 'daily progress',
        form: 'self reflection',
        reflection: reflectionText
      });
    } else {
      notifyError('Failed to create reflection');
    }
  };

  if (!dailyReflection)
    return (
      <div className={styles.skeleton}>
        <Skeleton variant="rect" height={50} />
        <Skeleton variant="rect" height={170} />
        <Skeleton variant="rect" height={50} />
      </div>
    );

  return (
    <div className={styles.container}>
      {dailyReflection.text ? (
        <DailyReflectionDisplay reflectionText={dailyReflection.text} />
      ) : (
        <DailyReflectionInput onSubmit={onSubmit} />
      )}
    </div>
  );
};

export default DailyReflection;
