import React from 'react';

import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import { splitLabelIntoMultiline } from 'pages/MisconceptionsDashboard/utils';

import Typography from 'components/Typography';

import MisconceptionTooltip from '../MisconceptionsComparisonTooltip';
import styles from './styles.module.scss';

export const BAR_COLORS = [
  'rgb(240,91,148)',
  'rgb(85,88,147)',
  'rgb(51,57,102)'
];
const BAR_WIDTH = 50;
const BAR_GAP = 15;
const BAR_CATEGORY_GAP = 20;
const MIN_CHART_WIDTH = 1050;

const MisconceptionComparisonBarChart = ({ misconceptionsData }) => {
  const diagramCount = misconceptionsData.length;
  if (diagramCount < 2 || diagramCount > 3) {
    return (
      <Typography variant="B-Text-1">
        Invalid number of data points to compare
      </Typography>
    );
  }

  // Extract all unique misconception categories from the data
  const allCategories = Array.from(
    new Set(
      misconceptionsData.flatMap(({ misconceptions }) =>
        misconceptions.map(({ category }) => category)
      )
    )
  );

  // Initialize the output template
  const outputDataTemplate = Object.fromEntries(
    allCategories.map((category) => [
      category,
      Object.fromEntries(
        misconceptionsData.map((_, index) => [`count${index + 1}`, 0])
      )
    ])
  );

  // Populate the counts for each category
  misconceptionsData.forEach((data, index) => {
    data.misconceptions.forEach(({ category, count }) => {
      if (outputDataTemplate[category]) {
        outputDataTemplate[category][`count${index + 1}`] = count;
      }
    });
  });

  // Transform outputDataTemplate to an array suitable for recharts
  const chartData = Object.entries(outputDataTemplate).map(
    ([category, counts]) => ({
      category,
      ...counts
    })
  );

  const innerChartWidth =
    chartData.length *
      // Number of data values per point (excluding label)
      (Object.keys(chartData[0]).length - 1) *
      // Total width per data point (bar + gap)
      (BAR_WIDTH + BAR_GAP) +
    // Extra space between categories
    chartData.length * BAR_CATEGORY_GAP;

  const CustomXAxisTick = ({ x, y, payload }) => {
    const lines = splitLabelIntoMultiline(payload.value, 10);
    const lineHeight = 18;

    return (
      <g transform={`translate(${x},${y + 10})`}>
        {lines.map((line, index) => (
          <text
            key={index}
            x={0}
            y={index * lineHeight}
            dy="0.35em"
            textAnchor="middle"
            fill="#333966"
          >
            {line}
          </text>
        ))}
      </g>
    );
  };

  return (
    <div className={styles.comparisonOuterContainer}>
      <div className={styles.comparisonChartContainer}>
        <div className={styles.chartWrapperOuterContainer}>
          <div
            className={styles.chartWrapperInnerContainer}
            style={{ width: Math.max(innerChartWidth, MIN_CHART_WIDTH) }}
          >
            <ResponsiveContainer>
              <BarChart
                data={chartData}
                barGap={BAR_GAP}
                barCategoryGap={BAR_CATEGORY_GAP}
                cursor="pointer"
                margin={{
                  top: 5,
                  right: 30,
                  left: 10,
                  bottom: 20
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="category"
                  height={150} // Adjust the height to fit multiline labels
                  tick={<CustomXAxisTick />}
                  interval={0}
                />
                <YAxis
                  label={{
                    value: 'Students',
                    angle: -90,
                    position: 'insideLeft',
                    dx: -7, // Adjust horizontal position if needed
                    dy: 40,
                    style: {
                      fontFamily: 'Nunito',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      fontSize: '20px',
                      fill: '#333966'
                    }
                  }}
                />
                <Tooltip content={<MisconceptionTooltip />} />

                {misconceptionsData.map((_, index) => (
                  <Bar
                    key={`count${index + 1}`}
                    dataKey={`count${index + 1}`}
                    fill={BAR_COLORS[index % diagramCount]}
                    background={{ fill: '#eee' }}
                  />
                ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MisconceptionComparisonBarChart;
