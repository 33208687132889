import React from 'react';

import NoAssignments from '../NoAssignments';
import styles from './styles.module.scss';
import { formatDateToString, getStatusIcon } from './utils';

const AssignmentsList = ({
  assignments,
  filteredAssignments,
  selectedDate
}) => {
  if (
    assignments &&
    assignments.length > 0 &&
    filteredAssignments.length === 0
  ) {
    return (
      <NoAssignments
        selectedDate={selectedDate}
        message={'No matching assignments found for selected criteria'}
      />
    );
  }

  if (!filteredAssignments || filteredAssignments.length === 0) {
    return <NoAssignments selectedDate={selectedDate} />;
  }

  return (
    <div className={styles.assignmentsList}>
      {filteredAssignments.map((assignment, index) => (
        <div key={assignment.id || index} className={styles.assignmentItem}>
          <div className={styles.assignmentMain}>
            <h3 className={styles.assignmentTitle}>{assignment.title}</h3>
            <div className={styles.assignmentClass}>
              {assignment.class_name}
              <div className={styles.creatorName}>
                {' '}
                {assignment.created_by.toUpperCase()}{' '}
              </div>
            </div>
            <p className={styles.assignmentDue}>
              Due: {formatDateToString(assignment.due_at, true)}
            </p>
          </div>
          <div className={styles.assignmentStatus}>
            <img
              src={getStatusIcon(assignment.status)}
              alt={`${assignment.status} icon`}
              className={styles.statusIcon}
            />
            <span
              className={`${styles.statusText} ${
                styles[assignment.status.toLowerCase()]
              }`}
            >
              {assignment.status.toUpperCase()}
            </span>
            <span className={styles.chevronRight}>›</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssignmentsList;
