import React, { useContext } from 'react';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import Typography from '@material-ui/core/Typography';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import { articleDisLike, articleLike, trackArticleCheck } from 'sdk';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { notifyErrors } from 'utils/notifications';
import { openInNewTab } from 'utils/urls';

import Button from 'components/Button';
import UserContext from 'components/UserContext';

import { ReactComponent as LinkIcon } from './assets/link.svg';
import styles from './styles.module.scss';

const CardResources = ({
  title,
  text,
  imageUrl,
  resourceUrl,
  likesCount,
  id,
  isLiked,
  isDisLiked,
  refetchArticles,
  refetchWeeklyProgress,
  selectedDate
}) => {
  // resource id supposedly to be used to fetch and create likes
  const user = useContext(UserContext);

  const resourceLike = async (event) => {
    // Prevent the parent link from being triggered when the user clicks to like this item
    event.stopPropagation();
    const { success, errors } = await articleLike({ id });

    if (success) {
      trackMixpanelEvent(user.id, 'User interacts with resource', {
        feature: 'pathways',
        view: 'daily progress resource',
        button: 'like',
        lesson: id
      });
      refetchArticles();
    } else {
      notifyErrors(errors);
    }
  };

  const resourceDisLike = async (event) => {
    // Prevent the parent link from being triggered when the user clicks to dislike this item
    event.stopPropagation();
    const { success, errors } = await articleDisLike({ id });

    if (success) {
      trackMixpanelEvent(user.id, 'User interacts with resource', {
        feature: 'pathways',
        view: 'daily progress resource',
        button: 'dislike',
        lesson: id
      });
      refetchArticles();
    } else {
      notifyErrors(errors);
    }
  };

  const handleArticleClick = async () => {
    openInNewTab(resourceUrl);

    const { success, errors } = await trackArticleCheck({ date: selectedDate });

    if (success) {
      trackMixpanelEvent(user.id, 'User interacts with resource', {
        feature: 'pathways',
        view: 'daily progress resource',
        button: 'click',
        lesson: id
      });
      refetchWeeklyProgress();
    } else {
      notifyErrors(errors);
    }
  };

  return (
    <Card
      className={styles.root}
      variant="outlined"
      onClick={handleArticleClick}
    >
      <div>
        <CardContent classes={{ root: styles.cardContent }}>
          <img src={imageUrl} alt="thumbnail" width={72} height={72} />
          <div>
            <Typography className={styles.cardTitle}>{title}</Typography>
            <Typography className={styles.cardText}>{text}</Typography>
          </div>
        </CardContent>
      </div>
      <CardActions disableSpacing>
        <div className={styles.thumbsButtonLayout}>
          <Button
            variant="textAndIcon"
            className={styles.likeButton}
            startIcon={<ThumbUpIcon />}
            onClick={resourceLike}
            style={isLiked ? { color: '#d03673' } : {}}
            disabled={isLiked}
          >
            {likesCount} Teachers liked this
          </Button>
          <IconButton
            size="small"
            className={styles.thumbDownButton}
            disabled={isDisLiked}
            onClick={resourceDisLike}
            style={isDisLiked ? { color: '#d03673' } : {}}
          >
            <ThumbDownIcon classes={{ root: styles.thumbDownIcon }} />
          </IconButton>
        </div>
        <div className={styles.spacer}></div>
        <SvgIcon component={LinkIcon} />
      </CardActions>
    </Card>
  );
};

export default CardResources;
