import React, { useState } from 'react';

import { useMixpanelPageOpenTrack } from 'utils/integrations/mixpanel';

import AssignmentCreateSelectFlow from './components/AssignmentCreateSelectFlow';
import CreateNewAssignment from './components/CreateNewAssignment';
import CurriculumNode from 'components/CurriculumNode';

import styles from './styles.module.scss';

const TeacherAssignmentCreate = () => {
  const [isFlowSelected, setIsFlowSelected] = useState(false);
  const [isCreateNewSelected, setIsCreateNewSelected] = useState(false);
  const [isCreateTemplateSelected, setIsCreateTemplateSelected] =
    useState(false);

  useMixpanelPageOpenTrack('Teacher opened Create page');

  return (
    <div className={styles.container}>
      {!isFlowSelected && (
        <AssignmentCreateSelectFlow
          setIsFlowSelected={setIsFlowSelected}
          setIsCreateNewSelected={setIsCreateNewSelected}
          setIsCreateTemplateSelected={setIsCreateTemplateSelected}
        />
      )}

      {isFlowSelected && isCreateNewSelected && <CreateNewAssignment />}

      {isFlowSelected && isCreateTemplateSelected && <CurriculumNode />}
    </div>
  );
};

export default TeacherAssignmentCreate;
