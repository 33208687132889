import React, { useState } from 'react';
import { withRouter } from 'react-router';

import MomentUtils from '@date-io/moment';
import { Box, Container, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MuiPickersUtilsProvider from '@material-ui/pickers/MuiPickersUtilsProvider';
import { LOGIN_URL } from 'config/urls';
import { isEmpty } from 'lodash';
import { logout, useFamilyStudentList } from 'sdk';

import BottomNavBar from 'pages/Family/components/BottomNavBar';

import StudenSelectDropdown from '../../components/StudentSelectDropdown';

import { useStudent } from '../FamilyContext';
import FamilyMemberRequired from '../FamilyMemberRequired';
import familyLogo from './assets/family-logo.svg';
// importing the custom styles
import styles from './styles.module.scss';
import { useCurrentPageName } from './utils';

const promptLogout = () => {
  const confirmed = window.confirm('Log out of EdLight?');
  return new Promise((resolve) => {
    if (confirmed) {
      resolve(logout());
    }
  });
};

const FamilyPageLayout = ({
  children,
  history,
  user,
  limitContentToCenterPortionOfTheScreen = true
}) => {
  const logout = () => promptLogout().then(() => history.push(LOGIN_URL));
  const { selectedStudent, setSelectedStudent } = useStudent();
  const { data: students, isLoading: isLoadingStudents } =
    useFamilyStudentList();
  const [selectedPage, setSelectedPage] = useState(useCurrentPageName(history));

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div className={styles.pageWrapper}>
        <div className={styles.topNavigation}>
          <Grid container justifyContent="space-around" spacing={2}>
            <IconButton aria-label="Account" size="medium" className="profile">
              <AccountCircleIcon color="primary" fontSize="large" />
            </IconButton>
            <Box>
              <img className={styles.familyLogo} src={familyLogo} alt="Logo" />
            </Box>
            {!isEmpty(students) && !isLoadingStudents && (
              <StudenSelectDropdown
                students={students}
                selectedStudent={selectedStudent}
                setSelectedStudent={setSelectedStudent}
              />
            )}
          </Grid>
        </div>
        <Container maxwidth="med" className={styles.content}>
          {children}
        </Container>
        <BottomNavBar
          logout={logout}
          history={history}
          selectedPage={selectedPage}
          setSelectedPage={setSelectedPage}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export default FamilyMemberRequired(withRouter(FamilyPageLayout));
