import React, { useContext, useRef } from 'react';

import { ChevronLeft, ChevronRight } from '@material-ui/icons';

import { StudentWorkCard as SharedStudentWorkCard } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const WorkCard = ({ data, isStrategy, onImageClick, dataType }) => {
  const scrollableContainerRef = useRef(null);
  const scrollStep = 350; // Width of one card plus the gap
  const user = useContext(UserContext);

  const scrollLeft = ({ dataType }) => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft -= scrollStep;
      trackMixpanelEvent(
        user.id,
        `[TrendsDashboard] User scrolled left the ${dataType} work card`,
        {
          direction: 'left'
        }
      );
    }
  };

  const scrollRight = ({ dataType }) => {
    if (scrollableContainerRef.current) {
      scrollableContainerRef.current.scrollLeft += scrollStep;
      trackMixpanelEvent(
        user.id,
        `[TrendsDashboard] User scrolled right the ${dataType} work card`,
        {
          direction: 'right'
        }
      );
    }
  };

  return (
    <div className={styles.outerContainer}>
      <Typography variant="H-TEXT-3" color={colors.blue2}>
        {isStrategy ? 'Complete and correct examples:' : 'Examples:'}
      </Typography>
      <div className={styles.worksContainer}>
        <ChevronLeft
          className={styles.arrowIcon}
          onClick={() => scrollLeft({ dataType })}
        />
        <div className={styles.workCardContainer} ref={scrollableContainerRef}>
          <div className={styles.scrollableContainer}>
            {data.map((data, index) => (
              <SharedStudentWorkCard
                key={index}
                imageUrl={data.imageUrl}
                classes={{ root: styles.studentCard, img: styles.studentCard }}
                onClick={() => onImageClick({ studentWork: data })}
              >
                <Typography variant="H-TEXT-3" className={styles.categoryName}>
                  {data.category}
                </Typography>
              </SharedStudentWorkCard>
            ))}
          </div>
        </div>
        <ChevronRight
          className={styles.arrowIcon}
          onClick={() => scrollRight({ dataType })}
        />
      </div>
    </div>
  );
};

export default WorkCard;
