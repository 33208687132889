import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

import {
  CLEVER_SSO_LOGIN_SOURCE,
  GOOGLE_SSO_LOGIN_SOURCE
} from 'config/constants';
import {
  TEACHERS_ASSIGNMENTS_URL,
  TEACHERS_BULK_UPLOAD_ASSIGN_ASSIGNMENT_URL,
  TEACHERS_BULK_UPLOAD_NAME_REGION_SELECT_URL,
  TEACHERS_BULK_UPLOAD_PAGE_CONFIRMATION_URL,
  TEACHERS_BULK_UPLOAD_SUBMISSIONS_MATCH_URL,
  TEACHERS_BULK_UPLOAD_SUCCESS_URL,
  TEACHERS_BULK_UPLOAD_WORK_UPLOAD_URL,
  TEACHERS_CAPTURE_URL,
  TEACHERS_MEETINGS_LIST_URL,
  TEACHERS_MEETING_FEED_URL,
  TEACHERS_MEETING_SORT_URL,
  TEACHERS_PERMISSION_DENIED_URL,
  TEACHERS_STUDENTS_LIST,
  TEACHERS_STUDENT_PORTFOLIO_ASSIGNMENT_DETAILS_URL,
  TEACHERS_STUDENT_PORTFOLIO_URL,
  TEACHERS_SUBSCRIPTIONS_URL,
  TEACHERS_TEAM_DETAIL_URL,
  TEACHER_DASHBOARD_URL,
  TEACHER_STRATEGIES_OVERVIEW_URL
} from 'config/urls';
import { get, includes, isUndefined } from 'lodash';
import mixpanel from 'mixpanel-browser';

import Dashboard from 'pages/MisconceptionsDashboard';
import ConfirmPages from 'pages/Teachers/BulkUpload/ConfirmPages';
import NameRegionSelect from 'pages/Teachers/BulkUpload/NameRegionSelect';
import StudentsConfirmation from 'pages/Teachers/BulkUpload/StudentsConfirmation';
import BulkUploadSuccess from 'pages/Teachers/BulkUpload/Success';
import TeamDetail from 'pages/Teachers/TeamDetail';
import { TeacherPageLayout, UsageDialog } from 'pages/Teachers/shared';
import { parseUsage } from 'pages/Teachers/shared/utils';
import { useCleverSync } from 'utils/integrations/clever';
import { useGoogleClassroomSync } from 'utils/integrations/googleClassroom';
import { parseParams } from 'utils/urls';

import IntegrationsSyncContext from 'components/IntegrationsSyncContext';
import TeacherRequired from 'components/TeacherRequired';
import UsageContext from 'components/UsageContext';
import UserContext from 'components/UserContext';

import Assignments from './Assignments';
import AssignAssignment from './BulkUpload/AssignAssignment';
import WorkUpload from './BulkUpload/WorkUpload';
import Capture from './Capture';
import MeetingFeed from './MeetingFeed';
import MeetingSort from './MeetingSort';
import MeetingsList from './MeetingsList';
import NoPermission from './NoPermission';
import StrategiesOverview from './StrategiesOverview';
import StudentPortfolio from './StudentPortfolio';
import StudentPortfolioAssignmentDetails from './StudentPortfolioAssignmentDetails';
import StudentsList from './StudentsList';
import Subscriptions from './Subscriptions';

const useUsageDialog = () => {
  const { user } = useContext(UserContext);
  const usage = parseUsage(user.usage);

  const [usageDialog, setUsageDialog] = useState(
    (usage && !usage.hasAccess) || false
  );

  const openDialog = () => {
    const shouldOpen = (!usageDialog && usage && !usage.hasAccess) || false;

    if (shouldOpen) {
      setUsageDialog(true);
    }
  };
  const closeDialog = () => {
    setUsageDialog(false);
  };

  return { usage, dialog: usageDialog, openDialog, closeDialog };
};

const Teachers = () => {
  const history = useHistory();

  const { dialog, openDialog, closeDialog, usage } = useUsageDialog();
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);

  const googleClassroom = useGoogleClassroomSync();
  const clever = useCleverSync();

  const { user } = useContext(UserContext);

  const searchParams = useMemo(
    () => parseParams(history.location.search),
    [history.location.search]
  );
  const loginSource = useMemo(
    () => get(searchParams, 'source', undefined),
    [searchParams]
  );

  useEffect(() => {
    if (
      !includes([GOOGLE_SSO_LOGIN_SOURCE, CLEVER_SSO_LOGIN_SOURCE], loginSource)
    )
      return;
    if (isUndefined(process.env.REACT_APP_MIXPANEL_KEY)) return;

    mixpanel.identify(String(user.id));
  }, [user, loginSource]);

  return (
    <UsageContext.Provider value={{ usage, openUsageDialog: openDialog }}>
      <IntegrationsSyncContext.Provider value={{ googleClassroom, clever }}>
        <TeacherPageLayout
          setSelectedOrganization={setSelectedOrganization}
          setSelectedSchool={setSelectedSchool}
          selectedOrganization={selectedOrganization}
        >
          <Switch>
            <Route path={TEACHERS_ASSIGNMENTS_URL} component={Assignments} />
            <Route
              path={TEACHERS_SUBSCRIPTIONS_URL}
              component={Subscriptions}
            />
            <Route exact path={TEACHERS_CAPTURE_URL} component={Capture} />
            <Route
              exact
              path={TEACHER_STRATEGIES_OVERVIEW_URL}
              render={(props) => (
                <StrategiesOverview
                  {...props}
                  selectedOrganization={selectedOrganization}
                  setSelectedOrganization={setSelectedOrganization}
                  selectedSchool={selectedSchool}
                  setSelectedSchool={setSelectedSchool}
                />
              )}
            />
            <Route exact path={TEACHER_DASHBOARD_URL} component={Dashboard} />
            <Route
              exact
              path={TEACHERS_STUDENTS_LIST}
              component={StudentsList}
            />
            <Route
              exact
              path={TEACHERS_STUDENT_PORTFOLIO_URL}
              component={StudentPortfolio}
            />
            <Route
              exact
              path={TEACHERS_STUDENT_PORTFOLIO_ASSIGNMENT_DETAILS_URL}
              component={StudentPortfolioAssignmentDetails}
            />
            <Route
              exact
              path={TEACHERS_TEAM_DETAIL_URL}
              component={TeamDetail}
            />
            <Route
              exact
              path={TEACHERS_MEETINGS_LIST_URL}
              component={MeetingsList}
            />
            <Route
              exact
              path={TEACHERS_MEETING_FEED_URL}
              component={MeetingFeed}
            />
            <Route
              exact
              path={TEACHERS_MEETING_SORT_URL}
              component={MeetingSort}
            />
            <Route
              exact
              path={TEACHERS_PERMISSION_DENIED_URL}
              component={NoPermission}
            />
            <Route
              exact
              path={TEACHERS_BULK_UPLOAD_WORK_UPLOAD_URL}
              component={WorkUpload}
            />
            <Route
              exact
              path={TEACHERS_BULK_UPLOAD_ASSIGN_ASSIGNMENT_URL}
              component={AssignAssignment}
            />
            <Route
              exact
              path={TEACHERS_BULK_UPLOAD_PAGE_CONFIRMATION_URL}
              component={ConfirmPages}
            />
            <Route
              exact
              path={TEACHERS_BULK_UPLOAD_NAME_REGION_SELECT_URL}
              component={NameRegionSelect}
            />
            <Route
              exact
              path={TEACHERS_BULK_UPLOAD_SUBMISSIONS_MATCH_URL}
              component={StudentsConfirmation}
            />
            <Route
              exact
              path={TEACHERS_BULK_UPLOAD_SUCCESS_URL}
              component={BulkUploadSuccess}
            />
            <Route path="*">
              <Redirect to="/" />
            </Route>
          </Switch>
          {dialog && <UsageDialog usage={usage} onClose={closeDialog} />}
        </TeacherPageLayout>
      </IntegrationsSyncContext.Provider>
    </UsageContext.Provider>
  );
};

export default TeacherRequired(Teachers);
