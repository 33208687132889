import React, { useMemo, useState } from 'react';

import { get } from 'lodash';
import {
  useDashboardMisconceptionStatistics,
  useDashboardStrategyStatistics
} from 'sdk';

import BarChartInfo from 'pages/MisconceptionsDashboard/components/BarChartInfo';
import DashboardSearchFilters from 'pages/MisconceptionsDashboard/components/DashboardSearchFilters';
import MisconceptionsBarChart from 'pages/MisconceptionsDashboard/components/MisconceptionsBarChart';
import NoResults from 'pages/MisconceptionsDashboard/components/NoResults';
import StrategiesBarChart from 'pages/MisconceptionsDashboard/components/StrategiesBarChart';
import StudentWorkViewDialog from 'pages/MisconceptionsDashboard/components/StudentWorkViewDialog';
import WorkCard from 'pages/MisconceptionsDashboard/components/WorkCard';
import {
  DASHBOARD_MISCONCEPTIONS,
  DASHBOARD_STRATEGIES
} from 'pages/MisconceptionsDashboard/constants';
import {
  getTopThreeMisconceptionLabels,
  getTopThreeStrategyLabels
} from 'pages/MisconceptionsDashboard/utils';

import styles from './styles.module.scss';

const DashboardView = ({
  selectedChart,
  selectedGrade,
  selectedUnit,
  selectedLesson,
  selectedOrganization,
  selectedSection,
  setSelectedSection,
  selectedSchool,
  setSelectedSchool,
  selectedUser,
  setSelectedUser
}) => {
  const [exampleWorks, setExampleWorks] = useState(null);

  const [isWorkDialogOpened, setIsWorkDialogOpened] = useState(false);
  const [selectedWork, setSelectedWork] = useState(null);

  const handleStudentWorkImageClick = ({ studentWork }) => {
    setSelectedWork(studentWork);
    setIsWorkDialogOpened(true);
  };

  const { data: strategyData, isLoading: isLoadingStrategies } =
    useDashboardStrategyStatistics({
      organizationId: get(selectedOrganization, 'id', null),
      params: {
        enabled: true,
        grade: get(selectedGrade, 'name', null),
        unit: get(selectedUnit, 'name', null),
        lesson: get(selectedLesson, 'name', null),
        school_id: get(selectedSchool, 'id', null),
        section_id: get(selectedSection, 'id', null),
        user_id: get(selectedUser, 'id', null)
      }
    });

  const { data: misconceptionData, isLoading: isLoadingMisconceptions } =
    useDashboardMisconceptionStatistics({
      organizationId: get(selectedOrganization, 'id', null),
      params: {
        enabled: true,
        grade: get(selectedGrade, 'name', null),
        unit: get(selectedUnit, 'name', null),
        lesson: get(selectedLesson, 'name', null),
        school_id: get(selectedSchool, 'id', null),
        section_id: get(selectedSection, 'id', null),
        user_id: get(selectedUser, 'id', null)
      }
    });

  const topStrategies = useMemo(
    () => getTopThreeStrategyLabels(strategyData),
    [strategyData]
  );
  const topMisconceptions = useMemo(
    () => getTopThreeMisconceptionLabels(misconceptionData),
    [misconceptionData]
  );

  const showNoResults =
    !isLoadingStrategies &&
    !isLoadingMisconceptions &&
    topStrategies === 'No results' &&
    topMisconceptions === 'No results';

  const isStrategyBarChart = selectedChart === DASHBOARD_STRATEGIES;

  return (
    <>
      {isWorkDialogOpened && (
        <StudentWorkViewDialog
          studentWork={selectedWork}
          setSelectedStudentWork={setSelectedWork}
          studentWorks={exampleWorks}
          onClose={() => setIsWorkDialogOpened(false)}
        />
      )}

      <DashboardSearchFilters
        organization={selectedOrganization}
        selectedSchool={selectedSchool}
        selectedSection={selectedSection}
        selectedUser={selectedUser}
        setSelectedSchool={setSelectedSchool}
        setSelectedSection={setSelectedSection}
        setSelectedUser={setSelectedUser}
      />
      {showNoResults && <NoResults />}

      {!showNoResults && (
        <>
          <div className={styles.dataContainer}>
            {selectedChart === DASHBOARD_STRATEGIES && (
              <StrategiesBarChart
                strategyData={strategyData}
                strategyExampleWorks={exampleWorks}
                setStrategyExampleWorks={setExampleWorks}
                isLoading={isLoadingStrategies}
              />
            )}
            {selectedChart === DASHBOARD_MISCONCEPTIONS && (
              <MisconceptionsBarChart
                misconceptionData={misconceptionData}
                misconceptionExampleWorks={exampleWorks}
                setMisconceptionExampleWorks={setExampleWorks}
                isLoading={isLoadingMisconceptions}
              />
            )}
            <BarChartInfo
              strategyData={topStrategies}
              misconceptionData={topMisconceptions}
              isLoadingStrategies={isLoadingStrategies}
              isLoadingMisconceptions={isLoadingMisconceptions}
            />
          </div>
          {exampleWorks && (
            <WorkCard
              data={exampleWorks}
              isStrategy={isStrategyBarChart}
              onImageClick={handleStudentWorkImageClick}
              dataType={selectedChart}
            />
          )}
        </>
      )}
    </>
  );
};

export default DashboardView;
