import React from 'react';
import { Link } from 'react-router-dom';

import SvgIcon from '@material-ui/core/SvgIcon';
import { isWidthUp } from '@material-ui/core/withWidth';
import classnames from 'classnames';
import { TEACHERS_ASSIGNMENTS_RESULTS_URL } from 'config/urls';
import _ from 'lodash';

import { ProgressBar } from 'pages/Teachers/shared';
import { formatDate } from 'utils/moment';
import { reverse } from 'utils/urls';

import Flex from 'components/Flex';
import Typography from 'components/Typography';

import sharedStyles from '../../styles.module.scss';
import { ReactComponent as AssignmentIcon } from './assets/assignment.svg';
import { ReactComponent as TutoringSessionIcon } from './assets/tutoring.svg';
import styles from './styles.module.scss';

const StudentsProgressBar = ({
  lgScreen,
  colors,
  width = 80,
  numStudents,
  totalStudents,
  barColor
}) => {
  const textColor = lgScreen ? colors.grey2 : colors.white;

  return (
    <Flex alignCenter>
      <Flex
        alignCenter
        flexStart={!lgScreen}
        className={classnames({
          [styles.progressBarWrapperTablet]: !lgScreen
        })}
      >
        <Typography
          variant="S-TEXT-2"
          color={textColor}
          className={classnames({
            [styles.progressTextTablet]: !lgScreen
          })}
        >
          {numStudents} / {totalStudents}
        </Typography>
        <ProgressBar
          width={width}
          value={numStudents}
          total={totalStudents}
          color={barColor}
          className={classnames({
            [styles.progressBar]: lgScreen,
            [styles.progressBarTablet]: !lgScreen
          })}
        />
      </Flex>
    </Flex>
  );
};

const HeaderRowLarge = ({ colors }) => (
  <div className={sharedStyles.headerRowLarge}>
    <Typography variant="H-TEXT-3" color={colors.greyDark} align="center">
      Due date
    </Typography>
    <Typography variant="H-TEXT-3" color={colors.greyDark} align="center">
      Assigned
    </Typography>
    <Typography variant="H-TEXT-3" color={colors.greyDark} align="center">
      Submitted
    </Typography>
    <Typography variant="H-TEXT-3" color={colors.greyDark} align="center">
      Revised
    </Typography>
    <Typography variant="H-TEXT-3" color={colors.greyDark} align="center">
      Complete
    </Typography>
  </div>
);

const HeaderRowTablet = ({ colors }) => (
  <div className={sharedStyles.headerRowTablet}>
    <div></div>
    <Typography variant="H-TEXT-3" color={colors.greyDark} align="center">
      Due
    </Typography>
    <Typography variant="H-TEXT-3" color={colors.greyDark} align="center">
      Assigned
    </Typography>
    <Typography variant="H-TEXT-3" color={colors.greyDark} align="center">
      Submitted
    </Typography>
    <Typography variant="H-TEXT-3" color={colors.greyDark} align="center">
      Revised
    </Typography>
    <Typography variant="H-TEXT-3" color={colors.greyDark} align="center">
      Complete
    </Typography>
  </div>
);

export const AssignmentRow = ({ width, trackerInstance, colors }) => {
  const lgScreen = isWidthUp('lg', width);

  const dateFormat = lgScreen ? 'ddd MM/DD hh:mm a' : 'MM/DD HH:mm';

  const isTutoringSession = _.some(
    trackerInstance.instance_sections,
    'is_tutoring_session'
  );

  return (
    <div className={sharedStyles.assignmentRow}>
      <Flex
        flexStart
        alignCenter={false}
        className={classnames(sharedStyles.titleRow, {
          [sharedStyles.titleRowTablet]: !lgScreen
        })}
      >
        <SvgIcon
          component={isTutoringSession ? TutoringSessionIcon : AssignmentIcon}
          className={classnames(sharedStyles.trackerIcon, {
            [sharedStyles.trackerIconLarge]: lgScreen
          })}
        />
        <Flex
          column={lgScreen}
          alignCenter={!lgScreen}
          spaceBetween={!lgScreen}
          className={sharedStyles.trackerName}
        >
          <Typography variant="H-TEXT-3" color={colors.blueDark}>
            {trackerInstance.tracker.name}
          </Typography>
          <Typography variant="B-Text-3" color={colors.grey2}>
            {_.get(trackerInstance.tracker, 'created_by.name')}
          </Typography>
        </Flex>
      </Flex>

      {lgScreen ? (
        <HeaderRowLarge colors={colors} />
      ) : (
        <HeaderRowTablet colors={colors} />
      )}

      {_.map(trackerInstance.instance_sections, (instanceSection) => {
        const studentsCount = _.sum(Object.values(instanceSection.statistics));
        const { assigned, submitted, revise, complete } =
          instanceSection.statistics;

        let resultsUrl = reverse(TEACHERS_ASSIGNMENTS_RESULTS_URL, {
          trackerId: trackerInstance.tracker.id
        });
        resultsUrl = `${resultsUrl}?section=${instanceSection.id}`;

        return (
          <Link
            key={instanceSection.id}
            to={resultsUrl}
            className={classnames(sharedStyles.sectionRow, 'pointer', {
              [sharedStyles.sectionRowTablet]: !lgScreen,
              [sharedStyles.sectionRowLarge]: lgScreen
            })}
          >
            <Typography
              variant="S-TEXT-1"
              color={colors.blue2}
              className={classnames({
                [sharedStyles.sectionTitle]: lgScreen
              })}
            >
              {instanceSection.section.name}
            </Typography>
            <Typography variant="B-Text-3" align="center" color={colors.grey2}>
              {formatDate(instanceSection.due_at, dateFormat)}
            </Typography>
            <StudentsProgressBar
              lgScreen={lgScreen}
              colors={colors}
              numStudents={assigned}
              totalStudents={studentsCount}
              barColor={colors.yellowDark}
            />
            <StudentsProgressBar
              lgScreen={lgScreen}
              colors={colors}
              numStudents={submitted}
              totalStudents={studentsCount}
              barColor={colors.blueLight}
            />
            <StudentsProgressBar
              lgScreen={lgScreen}
              colors={colors}
              numStudents={revise}
              totalStudents={studentsCount}
              barColor={colors.pink}
            />
            <StudentsProgressBar
              lgScreen={lgScreen}
              colors={colors}
              numStudents={complete}
              totalStudents={studentsCount}
              barColor={colors.greenDarker}
            />
          </Link>
        );
      })}
    </div>
  );
};
