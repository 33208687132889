import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

import withWidth from '@material-ui/core/withWidth';
import {
  PATHWAYS_DAILY_PROGRESS_URL,
  PATHWAYS_PROFILE_JOURNEY_URL,
  PATHWAYS_PROFILE_PERSONAL_GOALS_URL,
  PATHWAYS_RESULTS_URL
} from 'config/urls';
import { pathwaysUserGetJourneyProgress } from 'sdk';

import { withNavbar } from 'pages/Teachers/shared';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import PathwaysLogo from './components/PathwaysLogo/index';
import SwiperCarousel from './components/SwiperCarousel';
import Welcome from './components/Welcome';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const PathwaysStart = ({ navigation }) => {
  const [swiperFinished, setSwiperFinished] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const user = useContext(UserContext);

  const [pathwaysUserJourneyProgress, setPathwaysUserJourneyProgress] =
    useState();

  const fetchJourneyProgress = useCallback(async () => {
    const { data: pathwaysUserJourneyProgress } =
      await pathwaysUserGetJourneyProgress();

    setPathwaysUserJourneyProgress(pathwaysUserJourneyProgress);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.get('source') === 'sms_sending') {
      trackMixpanelEvent(user.id, 'Nudge Interaction', {
        feature: 'pathways',
        view: 'daily progress',
        source: 'nudge'
      });
    }
  }, [user.id, location.search]);

  useEffect(() => {
    if (pathwaysUserJourneyProgress?.progress_state === 'Journey Started') {
      history.push(PATHWAYS_PROFILE_JOURNEY_URL);
    }

    if (pathwaysUserJourneyProgress?.progress_state === 'Journey Completed') {
      history.push(PATHWAYS_PROFILE_PERSONAL_GOALS_URL);
    }
    if (
      pathwaysUserJourneyProgress?.progress_state === 'Personal Goals Submitted'
    ) {
      history.push(PATHWAYS_RESULTS_URL);
    }

    if (
      pathwaysUserJourneyProgress?.progress_state ===
      'Start Tracking Your Goals Clicked'
    ) {
      history.push(PATHWAYS_DAILY_PROGRESS_URL);
    }
  }, [history, pathwaysUserJourneyProgress]);

  useEffect(() => {
    if (!pathwaysUserJourneyProgress) {
      fetchJourneyProgress();
    }
  }, [pathwaysUserJourneyProgress, fetchJourneyProgress]);

  useEffect(() => {
    if (swiperFinished) {
      navigation.setLeftSubheader(
        <Typography
          noWrap
          variant="B-Text-1"
          className={styles.profileSetupTitle}
        >
          Profile Setup
        </Typography>
      );
    }
  }, [navigation, swiperFinished]);

  return (
    <div className={styles.pathwaysStart}>
      {pathwaysUserJourneyProgress?.progress_state ===
        'Journey Not Started' && (
        <>
          {!swiperFinished && (
            <SwiperCarousel onSwipeFinished={() => setSwiperFinished(true)} />
          )}
          {swiperFinished && <Welcome />}
        </>
      )}
    </div>
  );
};

export default withWidth()(
  withNavbar(PathwaysStart, {
    LeftSubheader: <PathwaysLogo />
  })
);
