import React, { useState } from 'react';

import _ from 'lodash';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import {
  SHORT_MASTERY_LEVEL_NAMES,
  shortenMasteryLevel
} from 'pages/MisconceptionsDashboard/utils';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const BAR_COLORS = ['rgb(240,91,148)', 'rgb(85,88,147)', 'rgb(51,57,102)'];
const BAR_WIDTH = 20;
const BAR_GAP = 5;
const BAR_CATEGORY_GAP = 30;
const MIN_CHART_WIDTH = 1050;

const StrategyComparisonBarChart = ({ strategyData }) => {
  const [hoveredData, setHoveredData] = useState(null);
  const diagramCount = strategyData.length;
  if (diagramCount < 2 || diagramCount > 3) {
    return (
      <Typography variant="B-Text-1">
        Invalid number of data points to compare
      </Typography>
    );
  }

  const extractedData = strategyData.map(({ strategies }) =>
    strategies.map(({ label, data }) =>
      data.map(({ mastery_level: masteryLevel, count: masteryCount }) => ({
        strategyName: label,
        masteryLevel,
        masteryCount
      }))
    )
  );

  const strategyNames = Array.from(
    new Set(
      extractedData.flatMap((school) =>
        school.map((strategyData) => strategyData[0]['strategyName'])
      )
    )
  );

  const outputDataTemplate = Object.fromEntries(
    strategyNames.map((strategyName) => [
      strategyName,
      Object.fromEntries(
        SHORT_MASTERY_LEVEL_NAMES.map((shortMasteryLevelName) => [
          shortMasteryLevelName,
          0
        ])
      )
    ])
  );

  const outputData = _.range(diagramCount).map(
    (_) => JSON.parse(JSON.stringify(outputDataTemplate)) // we need to deep clone the template otherwise it will be the same reference
  );

  extractedData.forEach((school, schoolIndex) => {
    school.forEach((strategy) => {
      strategy.forEach((masteryLevel) => {
        outputData[schoolIndex][masteryLevel['strategyName']][
          shortenMasteryLevel(masteryLevel['masteryLevel'])
        ] = masteryLevel['masteryCount'];
      });
    });
  });

  const chartData = strategyNames.map((strategyName) => ({
    name: strategyName,
    ...Object.fromEntries(
      outputData.flatMap((schoolData, schoolIndex) =>
        Object.entries(schoolData[strategyName]).map((masteryLevel) => [
          `${masteryLevel[0]}: School ${schoolIndex + 1}`,
          masteryLevel[1]
        ])
      )
    )
  }));

  // Calculate the total width needed for the chart
  const innerChartWidth =
    chartData.length *
      // Number of data values per point (excluding label)
      (Object.keys(chartData[0]).length - 1) *
      // Total width per data point (bar + gap)
      (BAR_WIDTH + BAR_GAP) +
    // Extra space between categories
    chartData.length * BAR_CATEGORY_GAP;

  const ComparisonTooltip = ({ active, payload, label }) => {
    const tooltipPayload = hoveredData?.tooltipPayload?.[0];
    if (!active || !payload || !payload.length || !tooltipPayload) return null;

    const masteryLevels = payload
      .map((item) => {
        const parts = item.dataKey.split(': ');
        return {
          masteryLevel: parts[0],
          schoolName: parts[1],
          value: item.value
        };
      })
      .filter(
        (item) => item.masteryLevel === tooltipPayload.name.split(': ')[0]
      );

    return (
      <div className={styles.tooltipContainer}>
        <Typography variant="H-TEXT-3" className={styles.tooltipText}>
          {label}
        </Typography>
        <Typography variant="H-TEXT-3" className={styles.tooltipText}>
          Mastery Levels:
        </Typography>
        <div className={styles.masteryText}>
          {masteryLevels.map((masteryLevel, index) => (
            <div key={index}>
              {masteryLevel.masteryLevel}: {masteryLevel.value}
            </div>
          ))}
        </div>
      </div>
    );
  };

  //custom background when hover per mastery level comparison group
  const CustomBackground = ({ x, y, width, height }) => {
    return (
      <rect
        className={styles.barBackgroundHover}
        x={x}
        y={y}
        width={width * diagramCount + BAR_GAP * (diagramCount - 1)}
        height={height}
        fill="rgba(238, 238, 238, 0.8)"
      />
    );
  };

  return (
    <div className={styles.comparisonOuterContainer}>
      <div className={styles.comparisonChartContainer}>
        <div className={styles.chartWrapperOuterContainer}>
          <div
            className={styles.chartWrapperInnerContainer}
            style={{ width: Math.max(innerChartWidth, MIN_CHART_WIDTH) }}
          >
            <ResponsiveContainer>
              <BarChart
                data={chartData}
                barGap={BAR_GAP}
                barCategoryGap={BAR_CATEGORY_GAP}
                cursor="pointer"
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" stroke="#333966" />
                <YAxis
                  label={{
                    value: 'Students',
                    angle: -90,
                    position: 'insideLeft',
                    dx: -10, // Adjust horizontal position if needed
                    dy: 40,
                    style: {
                      fontFamily: 'Nunito',
                      fontWeight: 400,
                      lineHeight: 'normal',
                      fontSize: '20px',
                      fill: '#333966'
                    }
                  }}
                />
                <Tooltip content={<ComparisonTooltip />} cursor={false} />

                {Object.keys(chartData[0])
                  .filter((k) => k !== 'name')
                  .sort()
                  .map((masteryLevelKey, index) => (
                    <Bar
                      key={masteryLevelKey}
                      barSize={BAR_WIDTH}
                      dataKey={masteryLevelKey}
                      fill={BAR_COLORS[index % diagramCount]}
                      background={
                        //create a single common grouping background bar for every diagramCount number of bars
                        index % diagramCount === 0 ? <CustomBackground /> : ''
                      }
                      onMouseEnter={(e) => setHoveredData(e)}
                      onMouseLeave={(e) => {
                        setHoveredData(null);
                      }}
                    />
                  ))}
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StrategyComparisonBarChart;
