import React, { useContext } from 'react';

import { SvgIcon } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as StarHalfIcon } from '../../assets/star_half.svg';
import { ReactComponent as StarFullIcon } from '../../assets/star_outline.svg';
import styles from './styles.module.scss';

const BarChartInfo = ({
  strategyData,
  misconceptionData,
  isLoadingStrategies,
  isLoadingMisconceptions
}) => {
  const { user } = useContext(UserContext);

  const topStrategiesText = user.is_PLAS_user
    ? 'Advanced Questions'
    : 'Top Strategies';

  return (
    <div className={styles.barChartContainer}>
      <div>
        <Typography variant="H-TEXT-1" className={styles.chartInfoTitles}>
          {topStrategiesText}
        </Typography>
        {isLoadingStrategies ? (
          <Skeleton variant="text" width={200} height={30} />
        ) : strategyData === 'No results' ? (
          <Typography variant="B-Text-1" className={styles.noResults}>
            No results
          </Typography>
        ) : (
          strategyData?.map((strategy, index) => (
            <div key={index} className={styles.chartInfoItem}>
              <SvgIcon component={StarFullIcon} />
              <Typography variant="B-Text-1">{strategy}</Typography>
            </div>
          ))
        )}
      </div>
      <div>
        <Typography variant="H-TEXT-1" className={styles.chartInfoTitles}>
          Misconceptions
        </Typography>
        {isLoadingMisconceptions ? (
          <Skeleton variant="text" width={200} height={30} />
        ) : misconceptionData === 'No results' ? (
          <Typography variant="B-Text-1" className={styles.noResults}>
            No results
          </Typography>
        ) : (
          misconceptionData?.map((misconception, index) => (
            <div key={index} className={styles.chartInfoItem}>
              <SvgIcon component={StarHalfIcon} />
              <Typography variant="B-Text-1">{misconception}</Typography>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default BarChartInfo;
