import { useContext, useEffect } from 'react';

import _ from 'lodash';
import mixpanel from 'mixpanel-browser';

import UserContext from 'components/UserContext';

import { trackMixpanelEventSdk } from './sdk';

const MIXPANEL_KEY = process.env.REACT_APP_MIXPANEL_KEY;

export const trackMixpanelEvent = (userId, eventName, eventData = {}) => {
  if (!_.isNil(MIXPANEL_KEY)) {
    mixpanel.track(eventName, { ...eventData, userId });
  }
};

export const useMixpanelPageOpenTrack = (eventName) => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    trackMixpanelEventSdk({
      user_id: user.id,
      event_name: eventName
    });
  }, [user.id, eventName]);
};
