import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import classnames from 'classnames';
import { PATHWAYS_PROFILE_PERSONAL_GOALS_URL } from 'config/urls';
import { filter, find, first, get, isEmpty, round, xorBy } from 'lodash';
import {
  pathwaysJourneyComplete,
  pathwaysProfileAnswersSubmit,
  usePathwaysProfileQuestions
} from 'sdk';

import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { notifyError, notifyErrors, notifySuccess } from 'utils/notifications';

import AlmostThere from './components/AlmostThere';
import Button from 'components/Button';
import UserContext from 'components/UserContext';

import { ReactComponent as BackButtonIcon } from './assets/back-arrow.svg';
import { ReactComponent as NextArrow } from './assets/next-arrow.svg';
import styles from './styles.module.scss';

const PathwaysProfileJourney = () => {
  const { data: questionsFromApi } = usePathwaysProfileQuestions();
  const user = useContext(UserContext);

  const history = useHistory();

  const [submittingAnswers, setSubmittingAnswers] = useState(false);

  /*
    The requirement in ordering the questions is as follows:
    1. Goal Questions
    2. Type of Class Questions
    3. Type of Learner Questions
    4. Type of Feedback Questions
  */
  const goalQuestions = filter(questionsFromApi, (question) => {
    return (
      question.category === 'Teacher Knowledge & Skill' ||
      question.category === 'Student Engagement' ||
      question.category === 'Content Rigor & Relevance'
    );
  });

  const typeOfClassQuestions = filter(
    questionsFromApi,
    (question) => question.category === 'Type of Class'
  );
  const typeOfLearnerQuestions = filter(
    questionsFromApi,
    (question) => question.category === 'Type of Learner'
  );
  const typeOfFeedbackQuestions = filter(
    questionsFromApi,
    (question) => question.category === 'Type of Feedback'
  );

  const questions = useMemo(
    () =>
      [
        ...goalQuestions,
        ...typeOfClassQuestions,
        ...typeOfLearnerQuestions,
        ...typeOfFeedbackQuestions
      ] || [],
    [
      goalQuestions,
      typeOfClassQuestions,
      typeOfLearnerQuestions,
      typeOfFeedbackQuestions
    ]
  );

  /*
    questions is in the following format:
    [
      {
        id: 1,    
        text: 'How often would you like to receive feedback and recommendations?',
        answers: [
          {
            id: 1,
            text: 'Daily, before my class'
          },
          {
            id: 2,
            text: 'Weekly'
          }, 
          ...
        ]
      },
      {
        id: 2,    
        text: 'What types of learning formats do you find most effective for your personal learning?',
        answers: [
          {
            id: 3,
            text: 'Text'
          },
          {
            id: 4,
            text: 'Video'
          }, 
          ...
        ]
      },
        

    ]
  */
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const [answersToQuestions, setAnswersToQuestions] = useState([]);
  /*
    answersToQuestions is in the following format:
    [
        {
            question: {
              id:1, 
              text: 'How often would you like to receive feedback and recommendations?',
            },
            selectedAnswers: [
              {
                id: 1,
                text: 'Daily, before my class'
              },
              {
                id: 2,
                text: 'Weekly'
              }
              ...
            ],
            answersAreSubmitted: true
        },
        ...
    ]
  */

  const [showAlmostThere, setShowAlmostThere] = useState(false);
  useEffect(() => {
    if (isEmpty(questionsFromApi)) return;

    const initialAnswersToQuestions = questionsFromApi.map((question) => ({
      question: question,
      selectedAnswers: [],
      answersAreSubmitted: false
    }));

    setAnswersToQuestions(initialAnswersToQuestions);
  }, [questionsFromApi]);

  const progress = useMemo(() => {
    const submittedQuestionsWithAnswers = filter(
      answersToQuestions,
      (answersToQuestion) =>
        !isEmpty(answersToQuestion.selectedAnswers) &&
        answersToQuestion.answersAreSubmitted === true
    ).length;

    return round((submittedQuestionsWithAnswers / questions.length) * 100, 0);
  }, [questions.length, answersToQuestions]);

  if (isEmpty(questions))
    return (
      <Box display="flex" alignItems="center" flex={1}>
        <Skeleton width="10%" /> - <Skeleton width="20%" />
      </Box>
    );

  const currentQuestion = questions[currentQuestionIndex];

  const selectAnswer = ({ answer }) => {
    const questionFromAnswers = first(
      filter(
        answersToQuestions,
        (answerToQuestions) =>
          answerToQuestions.question.text === currentQuestion.text
      )
    );

    let updatedAnswersToQuestion = [];

    if (questionFromAnswers.question.multi_select === true) {
      updatedAnswersToQuestion = xorBy(
        questionFromAnswers['selectedAnswers'],
        [answer],
        'text'
      );
    } else {
      updatedAnswersToQuestion = [answer];
    }

    const newAnswersToQuestion = [
      {
        question: currentQuestion,
        selectedAnswers: updatedAnswersToQuestion,
        answersAreSubmitted: false
      }
    ];

    const restAnswersToQuestions = filter(
      answersToQuestions,
      (answersToQuestion) =>
        answersToQuestion.question.text !== currentQuestion.text
    );

    setAnswersToQuestions([...restAnswersToQuestions, ...newAnswersToQuestion]);
  };

  const onBackButtonClick = () => {
    trackMixpanelEvent(user.id, 'User returns to previous  question', {
      feature: 'pathways',
      view: 'learner profile',
      button: 'back'
    });

    if (currentQuestionIndex === 0) return;

    if (showAlmostThere) {
      return setShowAlmostThere(false);
    }

    if (currentQuestionIndex === goalQuestions.length) {
      setShowAlmostThere(true);
    }

    setCurrentQuestionIndex(currentQuestionIndex - 1);
  };

  const submitAnswersForQuestion = () => {
    const answersToCurrentQuestion = filter(
      answersToQuestions,
      (answersToQuestion) =>
        answersToQuestion.question.text === currentQuestion.text
    )[0].selectedAnswers;

    const restAnswersToQuestions = filter(
      answersToQuestions,
      (answersToQuestion) =>
        answersToQuestion.question.text !== currentQuestion.text
    );

    const submittedAnswersToQuestions = [
      {
        question: currentQuestion,
        selectedAnswers: answersToCurrentQuestion,
        answersAreSubmitted: true
      }
    ];

    const newAnswersToQuestions = [
      ...restAnswersToQuestions,
      ...submittedAnswersToQuestions
    ];

    setAnswersToQuestions(newAnswersToQuestions);

    trackMixpanelEvent(user.id, 'User proceeds to next question', {
      feature: 'pathways',
      view: 'learner profile',
      form: 'question',
      question: currentQuestion.text,
      response: answersToCurrentQuestion.map((answer) => answer.text).join(', ')
    });

    /*
      Display the almost there screen when we've got all the goal questions answered
    */

    const allGoalQuestionsAnswered = filter(
      newAnswersToQuestions,
      (answersToQuestion) => {
        return (
          (answersToQuestion.question.category ===
            'Teacher Knowledge & Skill' ||
            answersToQuestion.question.category === 'Student Engagement' ||
            answersToQuestion.question.category ===
              'Content Rigor & Relevance') &&
          answersToQuestion.answersAreSubmitted === true
        );
      }
    );

    if (
      goalQuestions.length === allGoalQuestionsAnswered.length &&
      allGoalQuestionsAnswered.length > 0 &&
      currentQuestionIndex === goalQuestions.length - 1
    ) {
      return setShowAlmostThere(true);
    }

    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const submitAllAnswers = async () => {
    if (submittingAnswers) {
      return;
    }

    setSubmittingAnswers(true);

    const data = answersToQuestions.map((answersToQuestion) => ({
      id: answersToQuestion.question.id,
      answers: answersToQuestion.selectedAnswers.map((selectedAnswer) => ({
        id: selectedAnswer.id
      }))
    }));

    const { success, errors } = await pathwaysProfileAnswersSubmit({
      data
    });

    if (success) {
      notifySuccess('Profile answers successfully submitted');

      const { success: journeyFinished } = await pathwaysJourneyComplete();

      if (journeyFinished) {
        setSubmittingAnswers(false);

        trackMixpanelEvent(user.id, 'User finishes profile questionnaire', {
          feature: 'pathways',
          view: 'learner profile results'
        });

        history.push(PATHWAYS_PROFILE_PERSONAL_GOALS_URL);
      } else {
        notifyError('Could not finish the Pathways journey');
      }
    } else {
      notifyErrors(errors);
    }
  };

  const selectedAnswersForCurrentQuestion = get(
    first(
      filter(
        answersToQuestions,
        (answersToQuestion) =>
          answersToQuestion.question.text === currentQuestion.text
      )
    ),
    'selectedAnswers',
    []
  );

  const onNextButtonClick = () => {
    trackMixpanelEvent(user.id, 'User views breather', {
      feature: 'pathways',
      view: 'learner profile breather'
    });
    setShowAlmostThere(false);
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  return (
    <div className={styles.pathwaysProfileJourney}>
      <div className={styles.scrollableContainer}>
        <div className={styles.progressBar}>
          {currentQuestionIndex > 0 && (
            <div className={styles.backButton} onClick={onBackButtonClick}>
              <BackButtonIcon />
            </div>
          )}

          <LinearProgress
            className={styles.progress}
            value={progress}
            variant="determinate"
          />

          <div className={styles.percentage}>{progress}%</div>
        </div>

        {showAlmostThere && <AlmostThere />}

        {!showAlmostThere && (
          <>
            <div className={styles.questionText}>{currentQuestion.text}</div>

            {currentQuestion.answers.map((answer, index) => (
              <div
                key={index}
                className={classnames(styles.answer, {
                  [styles.selected]: !isEmpty(
                    find(selectedAnswersForCurrentQuestion, {
                      text: answer.text
                    })
                  )
                })}
                onClick={() => selectAnswer({ answer: answer })}
              >
                {answer.text}

                {!isEmpty(
                  find(selectedAnswersForCurrentQuestion, {
                    text: answer.text
                  })
                ) && <CheckCircleRoundedIcon className={styles.checkIcon} />}
              </div>
            ))}
          </>
        )}
      </div>

      {showAlmostThere && (
        <div className={styles.bottomButton}>
          <Button
            className={styles.submitButton}
            onClick={onNextButtonClick}
            endIcon={<NextArrow className={styles.icon} />}
          >
            Next
          </Button>
        </div>
      )}

      {!showAlmostThere && (
        <div className={styles.bottomButton}>
          {
            // If we are at the last question, show the "Finish" button
            currentQuestionIndex === questions.length - 1 && (
              <Button
                className={styles.submitButton}
                disabled={selectedAnswersForCurrentQuestion.length === 0}
                onClick={submitAllAnswers}
              >
                Finish
              </Button>
            )
          }
          {currentQuestionIndex < questions.length - 1 && (
            <Button
              className={styles.submitButton}
              disabled={
                selectedAnswersForCurrentQuestion.length === 0 ||
                submittingAnswers
              }
              onClick={submitAnswersForQuestion}
              endIcon={<NextArrow className={styles.icon} />}
            >
              Next
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default PathwaysProfileJourney;
