import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import { useUnitsForOrganization } from 'sdk';

import { TopBarSelect } from 'pages/Teachers/shared';
import { parseParams, stringifyParams } from 'utils/urls';

const UnitSelectDropdown = ({
  selectedOrganization,
  selectedGrade,
  selectedUnit,
  setSelectedUnit,
  setSelectedLesson
}) => {
  const { data: units, isLoading } = useUnitsForOrganization({
    organizationId: selectedOrganization.id,
    params: {
      grade: selectedGrade.name
    }
  });

  const history = useHistory();
  const urlParams = parseParams(history.location.search);
  const unitNumber = _.get(urlParams, 'unit');

  useEffect(() => {
    if (!isLoading) {
      if (!_.isEmpty(units)) {
        const unit = units.find((unit) => unit.name === unitNumber);
        if (unit) {
          setSelectedUnit(unit);
        } else {
          const defaultElement = units.find((element) => element.is_default);
          setSelectedUnit(defaultElement);

          urlParams.unit = defaultElement.name;
          history.replace({ search: stringifyParams(urlParams) });
        }
      } else {
        setSelectedUnit(null);

        delete urlParams.unit;
        delete urlParams.lesson;
        history.replace({ search: stringifyParams(urlParams) });
      }
    }
  }, [units, setSelectedUnit, unitNumber, history, urlParams, isLoading]);

  const selectUnit = useCallback(
    (newUnit) => {
      setSelectedLesson(null);
      setSelectedUnit(null);

      urlParams.unit = newUnit.name;
      history.replace({ search: stringifyParams(urlParams) });
    },
    [history, urlParams, setSelectedUnit, setSelectedLesson]
  );

  if (_.isEmpty(units)) return <></>;
  return (
    <TopBarSelect
      value={selectedUnit}
      renderValue={(unit) => `Unit ${_.get(unit, 'name')}`}
      onChange={selectUnit}
      options={units.map((unit) => ({
        value: unit,
        label: unit.name
      }))}
    />
  );
};

export default UnitSelectDropdown;
