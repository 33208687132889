import React, { useEffect, useState } from 'react';

import logoSmall from 'images/logo-small.svg';
import { useFamilyStudentAssignmentsList } from 'sdk';

import { formatDate } from 'utils/moment';

import AssignmentsList from './components/AssignmentsList';
import Calendar from './components/Calendar';

import { useStudent } from '../../shared/FamilyContext';
import styles from './styles.module.scss';

const Planner = () => {
  const { selectedStudent } = useStudent();
  const [status, setStatus] = useState('all');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [assignmentsData, setAssignmentsData] = useState(null);

  const { data } = useFamilyStudentAssignmentsList({
    studentId: selectedStudent?.id,
    params: { date: formatDate(selectedDate, 'YYYY-MM-DD') }
  });

  useEffect(() => {
    if (data) {
      setAssignmentsData(Array.isArray(data) ? data : [data]);
      setStatus('all');
    }
  }, [data]);

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const filteredAssignments = assignmentsData
    ? assignmentsData
        .map((assignment) => {
          if (
            assignment.status === 'assigned' &&
            new Date() > new Date(assignment.due_at)
          ) {
            return { ...assignment, status: 'missing' };
          }
          return assignment;
        })
        .filter(
          (assignment) =>
            status === 'all' || assignment.status.toLowerCase() === status
        )
    : [];

  if (!selectedStudent) {
    return (
      <div className={styles.loadingWrapper}>
        <img src={logoSmall} alt="Logo" />
        <p>Syncing your student's schedule...</p>
      </div>
    );
  }

  return (
    <div className={styles.plannerPage}>
      <div className={styles.calendarWrapper}>
        <Calendar
          student={selectedStudent}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </div>
      <div className={styles.statusFilter}>
        <select value={status} onChange={handleStatusChange}>
          <option value="all">All</option>
          <option value="assigned">Assigned</option>
          <option value="submitted">Submitted</option>
          <option value="missing">Missing</option>
          <option value="revise">Revise</option>
          <option value="complete">Complete</option>
        </select>
      </div>
      <div className={styles.assignmentsWrapper}>
        <AssignmentsList
          assignments={assignmentsData}
          filteredAssignments={filteredAssignments}
          selectedDate={selectedDate}
        />
      </div>
    </div>
  );
};

export default Planner;
