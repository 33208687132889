import React, { useCallback, useMemo, useState } from 'react';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { get } from 'lodash';
import {
  useDashboardMisconceptionStatistics,
  useDashboardStrategyStatistics
} from 'sdk';

import DashboardSearchFilters from 'pages/MisconceptionsDashboard/components/DashboardSearchFilters';
import MisconceptionComparisonBarChart from 'pages/MisconceptionsDashboard/components/MisconceptionComparisonBarChart';
import StrategyComparisonBarChart from 'pages/MisconceptionsDashboard/components/StrategyComparisonBarChart';
import {
  DASHBOARD_MISCONCEPTIONS,
  DASHBOARD_STRATEGIES
} from 'pages/MisconceptionsDashboard/constants';

import styles from './styles.module.scss';

const BAR_COLORS = ['rgb(240,91,148)', 'rgb(85,88,147)', 'rgb(51,57,102)'];

const ComparisonView = ({
  selectedChart,
  selectedGrade,
  selectedUnit,
  selectedLesson,
  selectedOrganization
}) => {
  const [sectionFirstDiagram, setSectionFirstDiagram] = useState(null);
  const [schoolFirstDiagram, setSchoolFirstDiagram] = useState(null);
  const [userFirstDiagram, setUserFirstDiagram] = useState(null);

  const [sectionSecondDiagram, setSectionSecondDiagram] = useState(null);
  const [schoolSecondDiagram, setSchoolSecondDiagram] = useState(null);
  const [userSecondDiagram, setUserSecondDiagram] = useState(null);

  const [sectionThirdDiagram, setSectionThirdDiagram] = useState(null);
  const [schoolThirdDiagram, setSchoolThirdDiagram] = useState(null);
  const [userThirdDiagram, setUserThirdDiagram] = useState(null);

  const [thirdComparisonDisabled, setThirdComparisonDisabled] = useState(true);

  const apiHook = useMemo(() => {
    if (selectedChart === DASHBOARD_STRATEGIES)
      return useDashboardStrategyStatistics;
    if (selectedChart === DASHBOARD_MISCONCEPTIONS)
      return useDashboardMisconceptionStatistics;
  }, [selectedChart]);

  const { data: dataFirstDiagram, isLoading: isLoadingFirstData } = apiHook({
    organizationId: get(selectedOrganization, 'id', null),
    params: {
      enabled: true,
      grade: get(selectedGrade, 'name', null),
      unit: get(selectedUnit, 'name', null),
      lesson: get(selectedLesson, 'name', null),
      school_id: get(schoolFirstDiagram, 'id', null),
      section_id: get(sectionFirstDiagram, 'id', null),
      user_id: get(userFirstDiagram, 'id', null)
    }
  });

  const { data: dataSecondDiagram, isLoading: isLoadingSecondData } = apiHook({
    organizationId: get(selectedOrganization, 'id', null),
    params: {
      enabled: true,
      grade: get(selectedGrade, 'name', null),
      unit: get(selectedUnit, 'name', null),
      lesson: get(selectedLesson, 'name', null),
      school_id: get(schoolSecondDiagram, 'id', null),
      section_id: get(sectionSecondDiagram, 'id', null),
      user_id: get(userSecondDiagram, 'id', null)
    }
  });

  const { data: dataThirdDiagram } = apiHook({
    organizationId: get(selectedOrganization, 'id', null),
    params: {
      enabled: !thirdComparisonDisabled,
      grade: get(selectedGrade, 'name', null),
      unit: get(selectedUnit, 'name', null),
      lesson: get(selectedLesson, 'name', null),
      school_id: get(schoolThirdDiagram, 'id', null),
      section_id: get(sectionThirdDiagram, 'id', null),
      user_id: get(userThirdDiagram, 'id', null)
    }
  });

  const checkboxOnClick = useCallback(() => {
    setThirdComparisonDisabled((prev) => !prev);
    setSchoolThirdDiagram(null);
    setSectionThirdDiagram(null);
    setUserThirdDiagram(null);
  }, []);

  return (
    <>
      <DashboardSearchFilters
        isDisabled={false}
        organization={selectedOrganization}
        selectedSchool={schoolFirstDiagram}
        selectedSection={sectionFirstDiagram}
        selectedUser={userFirstDiagram}
        setSelectedSchool={setSchoolFirstDiagram}
        setSelectedSection={setSectionFirstDiagram}
        setSelectedUser={setUserFirstDiagram}
        color={BAR_COLORS[0]}
      />
      <DashboardSearchFilters
        isDisabled={false}
        organization={selectedOrganization}
        selectedSchool={schoolSecondDiagram}
        selectedSection={sectionSecondDiagram}
        selectedUser={userSecondDiagram}
        setSelectedSchool={setSchoolSecondDiagram}
        setSelectedSection={setSectionSecondDiagram}
        setSelectedUser={setUserSecondDiagram}
        color={BAR_COLORS[1]}
      />
      <FormControlLabel
        className={styles.comparisonCheckbox}
        label="Include another comparison"
        control={<Checkbox onClick={checkboxOnClick} />}
      />
      <DashboardSearchFilters
        isDisabled={thirdComparisonDisabled}
        organization={selectedOrganization}
        selectedSchool={schoolThirdDiagram}
        selectedSection={sectionThirdDiagram}
        selectedUser={userThirdDiagram}
        setSelectedSchool={setSchoolThirdDiagram}
        setSelectedSection={setSectionThirdDiagram}
        setSelectedUser={setUserThirdDiagram}
        color={BAR_COLORS[2]}
      />
      <div className={styles.dataContainer}>
        {selectedChart === DASHBOARD_STRATEGIES &&
          !isLoadingFirstData &&
          !isLoadingSecondData && (
            <StrategyComparisonBarChart
              strategyData={[
                dataFirstDiagram,
                dataSecondDiagram,
                dataThirdDiagram
              ].filter((data) => data !== undefined)}
            />
          )}
        {selectedChart === DASHBOARD_MISCONCEPTIONS &&
          !isLoadingFirstData &&
          !isLoadingSecondData && (
            <MisconceptionComparisonBarChart
              misconceptionsData={[
                dataFirstDiagram,
                dataSecondDiagram,
                dataThirdDiagram
              ].filter((data) => data !== undefined)}
            />
          )}
      </div>
    </>
  );
};

export default ComparisonView;
