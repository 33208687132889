import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import SvgIcon from '@material-ui/core/SvgIcon';
import classnames from 'classnames';
import { get, isNil, size } from 'lodash';
import {
  getMisconceptionsDashboardExportURL,
  useAssignmentTemplateDetails,
  useOrganizationsSchools
} from 'sdk';

import { MISCONCEPTIONS_DASHBOARD_VIEW_OPTIONS } from 'pages/MisconceptionsDashboard/constants';
import {
  DASHBOARD_MISCONCEPTIONS,
  DASHBOARD_STRATEGIES
} from 'pages/MisconceptionsDashboard/constants';
import OrganizationSelectDialog from 'pages/Teachers/StrategiesOverview/components/OrganizationSelectDialog';
import { useNavbar } from 'pages/Teachers/shared';
import { usePreviousLocation } from 'pages/Teachers/shared/hooks';
import { colors } from 'theme/palette';
import {
  trackMixpanelEvent,
  useMixpanelPageOpenTrack
} from 'utils/integrations/mixpanel';
import { openInNewTab } from 'utils/urls';

import ComparisonView from './components/ComparisonView';
import DashboardView from './components/DashboardView';
import GradeSelectDropdown from './components/GradeSelectDropdown';
import LessonSelectDropdown from './components/LessonSelectDropdown';
import ToggleView from './components/ToggleView';
import UnitSelectDropdown from './components/UnitSelectDropdown';
import ExportButton from 'components/ExportButton';
import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import { ReactComponent as MainIcon } from './assets/bulbIcon.svg';
import styles from './styles.module.scss';

const StrategyOrMisconceptionForm = ({
  selectedChart,
  handleDashboardTypeChange
}) => {
  return (
    <>
      <FormControl
        component="fieldset"
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center'
        }}
      >
        <RadioGroup
          row
          aria-label="chart"
          name="chart1"
          value={selectedChart}
          onChange={handleDashboardTypeChange}
        >
          <FormControlLabel
            value={DASHBOARD_STRATEGIES}
            control={<Radio />}
            label="Strategies"
          />
          <FormControlLabel
            value={DASHBOARD_MISCONCEPTIONS}
            control={<Radio />}
            label="Misconceptions"
          />
        </RadioGroup>
      </FormControl>
    </>
  );
};

const Dashboard = () => {
  const { navigation } = useNavbar({ title: '' });

  const [selectedChart, setSelectedChart] = useState(DASHBOARD_STRATEGIES);

  const [selectedOrganization, setSelectedOrganization] = useState(null);

  const [selectedGrade, setSelectedGrade] = useState(null);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedLesson, setSelectedLesson] = useState(null);

  const [selectedSection, setSelectedSection] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const [selectedView, setSelectedView] = useState(
    MISCONCEPTIONS_DASHBOARD_VIEW_OPTIONS.OVERVIEW
  );

  const currentLocation = useLocation();
  const previousLocation = usePreviousLocation();

  const { data: organizations } = useOrganizationsSchools();
  const { data: assignmentTemplateDetails } = useAssignmentTemplateDetails({
    grade: get(selectedGrade, 'name', null),
    lesson: get(selectedLesson, 'name', null),
    unit: get(selectedUnit, 'name', null)
  });

  const user = useContext(UserContext);

  useEffect(() => {
    if (organizations?.length === 1) {
      setSelectedOrganization(organizations[0]);
    }
  }, [organizations]);

  useMixpanelPageOpenTrack('[TrendsDashboard] Misconceptions Dashboard Opened');

  const changeDashboardType = (event) => {
    const chartViewType = event.target.value;
    setSelectedChart(chartViewType);

    trackMixpanelEvent(
      user.id,
      `[TrendsDashboard] User selected chart view type: ${chartViewType}.`
    );
  };

  const handleSetSelectedView = (selectedView) => {
    setSelectedView(() => {
      trackMixpanelEvent(
        user.id,
        `[TrendsDashboard] User clicked ${selectedView} view.`
      );

      return selectedView;
    });
  };

  useEffect(() => {
    if (previousLocation.pathname !== currentLocation.pathname) {
      trackMixpanelEvent(
        user.id,
        `[TrendsDashboard] Coming to Misconceptions dashboard from ${previousLocation.pathname}`
      );
    }
  }, [user.id, previousLocation, currentLocation]);

  const exportResults = useCallback(() => {
    openInNewTab(
      getMisconceptionsDashboardExportURL({
        organizationId: selectedOrganization.id,
        grade: selectedGrade.name,
        unit: selectedUnit.name,
        lesson: selectedLesson.name,
        school: selectedSchool?.id,
        section: selectedSection?.id,
        user: selectedUser?.id
      })
    );
  }, [
    selectedOrganization,
    selectedGrade,
    selectedUnit,
    selectedLesson,
    selectedSchool,
    selectedSection,
    selectedUser
  ]);

  const handleExportClick = useCallback(() => {
    trackMixpanelEvent(
      user.id,
      '[TrendsDashboard] User exports the Misconceptions Dashboard',
      {
        organizationId: selectedOrganization.id,
        grade: selectedGrade.name,
        unit: selectedUnit.name,
        lesson: selectedLesson.name,
        schoolId: selectedSchool?.id,
        sectionId: selectedSection?.id,
        userId: selectedUser?.id
      }
    );

    exportResults();
  }, [
    user.id,
    selectedOrganization,
    selectedGrade,
    selectedUnit,
    selectedLesson,
    selectedSchool,
    selectedSection,
    selectedUser,
    exportResults
  ]);

  useEffect(() => {
    navigation.setLeftSubheader(
      <>
        {selectedOrganization && (
          <div className={styles.dropdown}>
            <GradeSelectDropdown
              selectedOrganization={selectedOrganization}
              selectedGrade={selectedGrade}
              setSelectedGrade={setSelectedGrade}
              setSelectedUnit={setSelectedUnit}
              setSelectedLesson={setSelectedLesson}
            />
          </div>
        )}
        {selectedOrganization && selectedGrade && (
          <div className={styles.dropdown}>
            <UnitSelectDropdown
              selectedOrganization={selectedOrganization}
              selectedGrade={selectedGrade}
              selectedUnit={selectedUnit}
              setSelectedUnit={setSelectedUnit}
              setSelectedLesson={setSelectedLesson}
            />
          </div>
        )}
        {selectedOrganization && selectedGrade && selectedUnit && (
          <div className={styles.dropdown}>
            <LessonSelectDropdown
              selectedOrganization={selectedOrganization}
              selectedGrade={selectedGrade}
              selectedUnit={selectedUnit}
              selectedLesson={selectedLesson}
              setSelectedLesson={setSelectedLesson}
            />
          </div>
        )}
      </>
    );
  }, [
    navigation,
    selectedOrganization,
    selectedGrade,
    selectedUnit,
    selectedLesson
  ]);

  if (size(organizations) && isNil(selectedOrganization)) {
    return (
      <OrganizationSelectDialog
        organizations={organizations}
        setSelectedSchool={() => {}}
        selectedOrganization={selectedOrganization}
        setSelectedOrganization={setSelectedOrganization}
        selectOnlyOrganization={true}
      />
    );
  }

  if (!selectedGrade || !selectedUnit || !selectedLesson) {
    return <></>;
  }

  const isComparisonView =
    selectedView === MISCONCEPTIONS_DASHBOARD_VIEW_OPTIONS.COMPARISON;

  return (
    <div>
      <div style={{ padding: '20px' }}>
        <div className={styles.title}>
          <Typography
            variant="H-TEXT-3"
            color={colors.blue3}
            className={styles.lessonName}
          >
            {assignmentTemplateDetails?.lesson_name}
          </Typography>

          <div className={styles.buttonsContainer}>
            {!isComparisonView && (
              <ExportButton handleClick={handleExportClick} />
            )}
            {process.env.REACT_APP_TRENDS_COMPARISON_VIEW_ENABLED ===
              'true' && (
              <ToggleView
                selectedView={selectedView}
                setSelectedView={handleSetSelectedView}
              />
            )}
          </div>
        </div>
        <Typography
          variant="H-TEXT-5"
          color={colors.blue3}
          className={classnames(styles.descriptions, styles.bold)}
        >
          Learning Targets
        </Typography>
        {assignmentTemplateDetails?.description
          .split('.')
          .filter((description) => description !== '')
          .map((sentence, index) => (
            <div key={index} className={styles.descriptionItem}>
              <SvgIcon component={MainIcon} />

              <Typography variant="H-TEXT-4" color={colors.blue3}>
                {sentence.trimStart()}.
              </Typography>
            </div>
          ))}
      </div>

      <StrategyOrMisconceptionForm
        selectedChart={selectedChart}
        handleDashboardTypeChange={changeDashboardType}
      />

      {!isComparisonView && (
        <DashboardView
          selectedChart={selectedChart}
          selectedGrade={selectedGrade}
          selectedUnit={selectedUnit}
          selectedLesson={selectedLesson}
          selectedOrganization={selectedOrganization}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
          selectedSchool={selectedSchool}
          setSelectedSchool={setSelectedSchool}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
        />
      )}

      {isComparisonView && (
        <ComparisonView
          selectedChart={selectedChart}
          selectedGrade={selectedGrade}
          selectedUnit={selectedUnit}
          selectedLesson={selectedLesson}
          selectedOrganization={selectedOrganization}
        />
      )}
    </div>
  );
};

export default Dashboard;
