import React, { useCallback, useContext, useEffect, useState } from 'react';

import Box from '@material-ui/core/Box';
import { get, isUndefined } from 'lodash';
import { useOrganizationSectionsShoolsAndUsers } from 'sdk';

import { loadDashboardFilterData } from 'pages/MisconceptionsDashboard/utils';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import SearchFilter from 'components/SearchFilter';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

const DashboardSearchFilters = ({
  isDisabled,
  organization,
  selectedSchool,
  selectedSection,
  selectedUser,
  setSelectedSchool,
  setSelectedSection,
  setSelectedUser,
  color
}) => {
  const [currentSchools, setCurrentSchools] = useState([]);
  const [currentSections, setCurrentSections] = useState([]);
  const [currentUsers, setCurrentUsers] = useState([]);
  const { user } = useContext(UserContext);

  const { data: sectionsData, isLoading } =
    useOrganizationSectionsShoolsAndUsers({
      organizationId: organization.id
    });

  useEffect(() => {
    if (!isUndefined(sectionsData) && !isLoading) {
      const [schools, sections, users] = loadDashboardFilterData(
        sectionsData,
        null,
        null,
        null
      );

      if (schools.length === 1) setSelectedSchool(schools[0]);
      if (sections.length === 1) setSelectedSection(sections[0]);
      if (users.length === 1) setSelectedUser(users[0]);

      setCurrentSchools(schools);
      setCurrentSections(sections);
      setCurrentUsers(users);
    }
  }, [
    setSelectedSchool,
    setSelectedSection,
    setSelectedUser,
    sectionsData,
    isLoading
  ]);

  const onSchoolChange = useCallback(
    (event, newSchool) => {
      const [schools, sections, users] = loadDashboardFilterData(
        sectionsData,
        newSchool,
        selectedSection,
        selectedUser
      );

      setCurrentSchools(schools);
      setCurrentSections(sections);
      setCurrentUsers(users);

      if (sections.length === 1) setSelectedSection(sections[0]);
      if (users.length === 1) setSelectedUser(users[0]);

      setSelectedSchool(newSchool);

      if (newSchool) {
        trackMixpanelEvent(
          user.id,
          `[TrendsDashboard] User changed school filter to ${newSchool.name}`,
          { newSchool: newSchool.name }
        );
      } else {
        trackMixpanelEvent(
          user.id,
          `[TrendsDashboard] User changed school filter to All Schools`,
          { newSchool: 'All Schools' }
        );
      }
    },
    [
      setSelectedSchool,
      setSelectedSection,
      setSelectedUser,
      selectedSection,
      selectedUser,
      sectionsData,
      user.id
    ]
  );

  const onSectionChange = useCallback(
    (event, newSection) => {
      const [schools, sections, users] = loadDashboardFilterData(
        sectionsData,
        selectedSchool,
        newSection,
        selectedUser
      );

      setCurrentSchools(schools);
      setCurrentSections(sections);
      setCurrentUsers(users);

      if (schools.length === 1) setSelectedSchool(schools[0]);
      if (users.length === 1) setSelectedUser(users[0]);

      setSelectedSection(newSection);

      if (newSection) {
        trackMixpanelEvent(
          user.id,
          `[TrendsDashboard] User changed section filter to ${newSection.name}`,
          { newSection: newSection.name }
        );
      } else {
        trackMixpanelEvent(
          user.id,
          `[TrendsDashboard] User changed section filter to All Sections`,
          { newSection: 'All Sections' }
        );
      }
    },
    [
      setSelectedSchool,
      setSelectedSection,
      setSelectedUser,
      selectedSchool,
      selectedUser,
      sectionsData,
      user.id
    ]
  );

  const onUserChange = useCallback(
    (event, newUser) => {
      const [schools, sections, users] = loadDashboardFilterData(
        sectionsData,
        selectedSchool,
        selectedSection,
        newUser
      );

      setCurrentSchools(schools);
      setCurrentSections(sections);
      setCurrentUsers(users);

      if (schools.length === 1) setSelectedSchool(schools[0]);
      if (sections.length === 1) setSelectedSection(sections[0]);

      setSelectedUser(newUser);

      if (newUser) {
        const fullName = `${newUser.first_name} ${newUser.last_name}`;
        trackMixpanelEvent(
          user.id,
          `[TrendsDashboard] User changed user filter to ${fullName}`,
          { newUser: fullName }
        );
      } else {
        trackMixpanelEvent(
          user.id,
          `[TrendsDashboard] User changed user filter to All Users`,
          { newUser: 'All Users' }
        );
      }
    },
    [
      setSelectedSchool,
      setSelectedSection,
      setSelectedUser,
      selectedSchool,
      selectedSection,
      sectionsData,
      user.id
    ]
  );

  const getUserOptionLabel = (user) => {
    const firstName = get(user, 'first_name', '');
    const lastName = get(user, 'last_name', '');
    if (firstName || lastName) return `${firstName} ${lastName}`;
    return '';
  };

  return (
    <div className={styles.filterContainer}>
      <Box bgcolor={isDisabled ? '' : color} sx={{ width: 24, height: 24 }} />
      <SearchFilter
        className={styles.filter}
        fullWidth
        withWhiteBackground
        placeholder="All Schools"
        disabled={isDisabled}
        options={currentSchools}
        value={selectedSchool}
        getOptionLabel={(school) => get(school, 'name', '')}
        getOptionSelected={(school, value) => school.id === value.id}
        onChange={onSchoolChange}
      />

      <SearchFilter
        className={styles.filter}
        fullWidth
        withWhiteBackground
        placeholder="All Sections"
        disabled={isDisabled}
        options={currentSections}
        value={selectedSection}
        getOptionLabel={(section) => get(section, 'name', '')}
        getOptionSelected={(section, value) => section.id === value.id}
        onChange={onSectionChange}
      />

      <SearchFilter
        className={styles.filter}
        fullWidth
        withWhiteBackground
        placeholder="All Users"
        disabled={isDisabled}
        options={currentUsers}
        value={selectedUser}
        getOptionLabel={getUserOptionLabel}
        getOptionSelected={(user, value) => user.id === value.id}
        onChange={onUserChange}
      />
    </div>
  );
};

export default DashboardSearchFilters;
