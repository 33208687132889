import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';

import { get, isNil, isNull } from 'lodash';
import {
  pathwaysGetArticlesForDate,
  pathwaysGetDailyMemeForDate,
  pathwaysGetDailyMiniLesson,
  pathwaysGetDailyReflection,
  pathwaysGetTipOfTheDay,
  usePathwaysWeeklyProgress
} from 'sdk';

import BottomNavigation from 'pages/Pathways/components/BottomNavigation';
import DailyReflection from 'pages/Pathways/components/PathwaysDailyProgress/components/DailyReflection';
import StudentWork from 'pages/Pathways/components/PathwaysDailyProgress/components/StudentWork';
import TipPanel from 'pages/Pathways/components/PathwaysDailyProgress/components/TipPanel';
import WeeklyProgressBar from 'pages/Pathways/components/PathwaysDailyProgress/components/WeeklyProgressBar';
import { buildFilters } from 'utils/filters';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';
import { notifyError } from 'utils/notifications';

import DailyMeme from './components/DailyMeme';
import MiniLesson from './components/MiniLesson';
import RecommendedResources from './components/RecommendedResources';
import UserContext from 'components/UserContext';

import AskEmberButton from '../AskEmberButton/index';
import styles from './styles.module.scss';

const NUMBER_TO_DAY_LETTER_MAPPING = {
  1: 'M',
  2: 'T',
  3: 'W',
  4: 'T',
  5: 'F'
};

const PathwaysDailyProgress = () => {
  const [selectedDayOfTheWeek, setSelectedDayOfTheWeek] = useState(null);
  const user = useContext(UserContext);

  const userTimezone = useMemo(
    () => Intl.DateTimeFormat().resolvedOptions().timeZone,
    []
  );

  const { data: weeklyProgress, refetch: refetchWeeklyProgress } =
    usePathwaysWeeklyProgress({ timezone: userTimezone });

  const [dailyReflection, setDailyReflection] = useState(null);
  const [tipOfTheDay, setTipOfTheDay] = useState(null);
  const [miniLesson, setMiniLesson] = useState(null);
  const [articles, setArticles] = useState(null);
  const [dailyMeme, setDailyMeme] = useState(null);

  const daysOfTheWeek = useMemo(() => {
    if (!isNil(weeklyProgress)) {
      return weeklyProgress.map((dayOfTheWeek) => ({
        value: dayOfTheWeek.day,
        title: NUMBER_TO_DAY_LETTER_MAPPING[dayOfTheWeek.day],
        isSelected: selectedDayOfTheWeek === dayOfTheWeek.day,
        isAvailable: dayOfTheWeek.available,
        isCurrentDay: dayOfTheWeek.is_current_day,
        date: dayOfTheWeek.date,
        progress: dayOfTheWeek.progress
      }));
    }
  }, [selectedDayOfTheWeek, weeklyProgress]);

  useEffect(() => {
    if (isNull(selectedDayOfTheWeek) && !isNil(weeklyProgress)) {
      const currentDayOfTheWeek = weeklyProgress.find(
        (dayOfTheWeek) => dayOfTheWeek.is_current_day
      );
      if (currentDayOfTheWeek) {
        setSelectedDayOfTheWeek(currentDayOfTheWeek.day);
      }
    }
  }, [selectedDayOfTheWeek, weeklyProgress]);

  const fetchDailyReflection = useCallback(async ({ selectedDate }) => {
    const {
      data: dailyReflectionData,
      success,
      errors
    } = await pathwaysGetDailyReflection(buildFilters({ date: selectedDate }));

    if (success) {
      setDailyReflection(dailyReflectionData);
    } else {
      notifyError(errors);
    }
  }, []);

  const fetchMiniLesson = useCallback(
    async ({ selectedDate }) => {
      const {
        data: miniLessonData,
        success,
        errors
      } = await pathwaysGetDailyMiniLesson(
        buildFilters({ date: selectedDate, timezone: userTimezone })
      );

      if (success) {
        setMiniLesson(miniLessonData);
      } else {
        notifyError(errors);
      }
    },
    [userTimezone]
  );

  const fetchTipOfTheDay = useCallback(async ({ selectedDate }) => {
    const {
      data: tipOfTheDay,
      success,
      errors
    } = await pathwaysGetTipOfTheDay(buildFilters({ day: selectedDate }));

    if (success) {
      setTipOfTheDay(tipOfTheDay);
    } else {
      notifyError(errors);
    }
  }, []);

  const fetchArticlesForDate = useCallback(async ({ selectedDate }) => {
    const {
      data: article,
      success,
      errors
    } = await pathwaysGetArticlesForDate(buildFilters({ date: selectedDate }));

    if (success) {
      setArticles(article);
    } else {
      notifyError(errors);
    }
  }, []);

  const fetchDailyMeme = useCallback(async ({ selectedDate }) => {
    const { data: dailyMeme, success } = await pathwaysGetDailyMemeForDate(
      buildFilters({ date: selectedDate })
    );

    // If there is no meme, we don't want to display error. We just do not display the meme. This is the requirement
    if (success) {
      setDailyMeme(dailyMeme);
    } else {
      setDailyMeme(null);
    }
  }, []);

  const selectedDate = useMemo(
    () =>
      get(
        weeklyProgress &&
          weeklyProgress.find((day) => day.day === selectedDayOfTheWeek),
        'date',
        ''
      ),
    [weeklyProgress, selectedDayOfTheWeek]
  );

  useEffect(() => {
    if (selectedDate) {
      fetchArticlesForDate({ selectedDate });
      fetchDailyReflection({ selectedDate });
      fetchMiniLesson({ selectedDate });
      fetchTipOfTheDay({ selectedDate });
      fetchDailyMeme({ selectedDate });
    }
  }, [
    selectedDate,
    fetchArticlesForDate,
    fetchDailyReflection,
    fetchMiniLesson,
    fetchTipOfTheDay,
    fetchDailyMeme
  ]);

  if (!weeklyProgress) {
    // Add loading state
    return null;
  }

  const refetchDailyProgress = () => {
    refetchWeeklyProgress();
    fetchDailyReflection({ selectedDate });
  };

  const refetchMiniLesson = () => {
    fetchMiniLesson({ selectedDate });
  };

  const refetchArticles = () => {
    fetchArticlesForDate({ selectedDate });
  };

  const refetchArticlesAndWeeklyProgress = () => {
    refetchWeeklyProgress();
    refetchArticles();
  };

  const trackScrollToBottom = (event) => {
    const target = event.target;

    if (
      dailyMeme?.meme_url &&
      target.scrollHeight - target.scrollTop === target.clientHeight
    ) {
      trackMixpanelEvent(user.id, 'User views daily meme', {
        feature: 'pathways',
        view: 'daily progress meme'
      });
    }
  };

  return (
    <div className={styles.scrollableContainer} onScroll={trackScrollToBottom}>
      <div className={styles.progressContent}>
        <WeeklyProgressBar
          days={daysOfTheWeek}
          selectedDayOfTheWeek={selectedDayOfTheWeek}
          setSelectedDayOfTheWeek={setSelectedDayOfTheWeek}
        />
      </div>
      <div className={styles.content}>
        <TipPanel
          tipOfTheDay={tipOfTheDay}
          onLikeDislikeSuccess={() => fetchTipOfTheDay({ selectedDate })}
        />
        <MiniLesson
          miniLesson={miniLesson}
          refetchMiniLesson={refetchMiniLesson}
        />
        <StudentWork date={selectedDate} />
        <DailyReflection
          dailyReflection={dailyReflection}
          selectedDate={selectedDate}
          refetchDaiyReflection={refetchDailyProgress}
        />
        <RecommendedResources
          articles={articles}
          refetchArticles={refetchArticles}
          refetchWeeklyProgress={refetchArticlesAndWeeklyProgress}
          selectedDate={selectedDate}
        />
        {dailyMeme?.meme_url && <DailyMeme dailyMeme={dailyMeme} />}
      </div>
      <AskEmberButton />
      <BottomNavigation />
    </div>
  );
};

export default PathwaysDailyProgress;
