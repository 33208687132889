import React from 'react';

import Typography from 'components/Typography';

import { ReactComponent as AlmostThereImage } from './assets/almost-there.svg';
import styles from './styles.module.scss';

const AlmostThere = ({ onSubmit }) => {
  return (
    <div className={styles.almostThere}>
      <AlmostThereImage className={styles.image} />

      <div className={styles.textWrapper}>
        <Typography variant="H-TEXT-1" className={styles.title}>
          Almost there! <br /> Now let's shift gears.
        </Typography>
        <Typography variant="B-Text-2" className={styles.text}>
          We'd love to understand what types of resources are most useful to you
          and how often you'd like to receive feedback from Pathways.
        </Typography>
      </div>
    </div>
  );
};

export default AlmostThere;
