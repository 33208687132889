import React, { useCallback } from 'react';

import { TEACHER_STRATEGIES_OVERVIEW_URL } from 'config/urls';

import { useNavbar } from 'pages/Teachers/shared';
import { colors } from 'theme/palette';

import Image from 'components/Image';
import Typography from 'components/Typography';

import noPermissionImage from './assets/no_permission.png';
import styles from './styles.module.scss';

const NoPermission = ({ history }) => {
  const onBackClick = useCallback(() => {
    if (history.length > 2) {
      // Go back two pages
      // This is used because the previous page is in fact the page that raised 403
      history.go(-2);
    } else {
      const redirectUrl = TEACHER_STRATEGIES_OVERVIEW_URL;

      history.push(redirectUrl);
    }
  }, [history]);

  useNavbar({
    title: 'Back',
    onTitleClick: onBackClick,
    onBackButtonClick: onBackClick,
    sticky: true
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Image src={noPermissionImage} className={styles.image} />
        <div className={styles.text}>
          <Typography variant="H-TEXT-1" color={colors.pink1}>
            Off Limits
          </Typography>
          <Typography variant="H-TEXT-2" color={colors.blue2}>
            You don't have permission
          </Typography>
          <Typography variant="H-TEXT-2" color={colors.blue2}>
            to view this page
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default NoPermission;
