import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import {
  PATHWAYS_INSIGHTS_URL,
  PATHWAYS_START_URL,
  TEACHERS_ASSIGNMENTS_LIST_URL,
  TEACHERS_CAPTURE_URL,
  TEACHERS_MEETINGS_LIST_URL,
  TEACHERS_STUDENTS_LIST,
  TEACHER_DASHBOARD_URL,
  TEACHER_STRATEGIES_OVERVIEW_URL
} from 'config/urls';
import { ReactComponent as ResultsIcon } from 'images/results.svg';

import { ReactComponent as AssignmentsIcon } from './assets/assignments.svg';
import { ReactComponent as CreateIcon } from './assets/create-plus.svg';
import { ReactComponent as DashboardIcon } from './assets/dashboard.svg';
import { ReactComponent as BulbIcon } from './assets/light-bulb.svg';
import { ReactComponent as MeetingsIcon } from './assets/meetings.svg';
import { ReactComponent as PathwaysIcon } from './assets/pathways.svg';

const getMisconceptionDashboard = () => [
  {
    key: 'trends',
    text: 'Trends',
    url: TEACHER_DASHBOARD_URL,
    Icon: (props) => <SvgIcon {...props} component={DashboardIcon} />
  }
];

const pathwaysItem = {
  key: 'pathways',
  text: 'Pathways',
  url: PATHWAYS_START_URL,
  Icon: (props) => (
    <SvgIcon {...props} component={PathwaysIcon} viewBox="2 2 20 20" />
  )
};

const getPathwaysItemsForMobile = () => [
  pathwaysItem,
  {
    key: 'insights',
    text: 'Insights',
    url: PATHWAYS_INSIGHTS_URL,
    Icon: (props) => <SvgIcon {...props} component={BulbIcon} />
  }
];

export const getLeftNavigationItems = ({ user, isMobile }) => {
  const userIsPathwaysUser = user.is_pathways_user;
  const pathwaysEnabled =
    userIsPathwaysUser && process.env.REACT_APP_PATHWAYS_ENABLED === 'true';

  // On a mobile device, when pathways is enabled we need to include only some items plus the pathways items
  if (isMobile && pathwaysEnabled) {
    return getPathwaysItemsForMobile();
  }

  // On desktop we need to include all items plus the pathways items in case it's enabled
  return [
    {
      key: 'create',
      text: 'Create Assignment',
      shortText: 'Create',
      url: TEACHERS_CAPTURE_URL,
      Icon: (props) => <CreateIcon {...props} />,
      dataIntercomTargetName: 'Left Navigation Assignment Create Button'
    },
    {
      key: 'capture',
      text: 'Capture',
      url: TEACHERS_CAPTURE_URL,
      Icon: (props) => <CameraAltIcon {...props} />
    },
    {
      key: 'strategies-overview',
      text: 'Insights',
      url: TEACHER_STRATEGIES_OVERVIEW_URL,
      Icon: (props) => <SvgIcon {...props} component={BulbIcon} />
    },
    ...getMisconceptionDashboard(),
    {
      key: 'assignments',
      text: 'Assignments',
      url: TEACHERS_ASSIGNMENTS_LIST_URL,
      Icon: (props) => <SvgIcon {...props} component={AssignmentsIcon} />
    },
    {
      key: 'students',
      text: 'Students',
      url: TEACHERS_STUDENTS_LIST,
      Icon: (props) => <SvgIcon {...props} component={ResultsIcon} />
    },
    {
      key: 'meetings',
      text: 'Meetings',
      Icon: (props) => <SvgIcon {...props} component={MeetingsIcon} />,
      url: TEACHERS_MEETINGS_LIST_URL
    },
    ...(pathwaysEnabled ? [pathwaysItem] : [])
  ];
};
