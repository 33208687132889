import React from 'react';

import Typography from '@material-ui/core/Typography';

import { BAR_COLORS } from '../MisconceptionComparisonBarChart';
import styles from './styles.module.scss';

const MisconceptionTooltip = ({ active, payload, label }) => {
  if (!active || !payload || payload.length === 0) return null;

  return (
    <div className={styles.tooltipContainer}>
      <Typography variant="H-TEXT-3" className={styles.tooltipText}>
        {label}
      </Typography>
      {payload.map((item, index) => (
        <div key={index} className={styles.diagramText}>
          <div
            style={{
              width: '25px',
              height: '25px',
              backgroundColor: BAR_COLORS[index],
              display: 'inline-block',
              marginRight: '8px',
              marginTop: '3px'
            }}
          />
          {`${item.value} students`}
        </div>
      ))}
    </div>
  );
};

export default MisconceptionTooltip;
