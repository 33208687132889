import React from 'react';

import DescriptionIcon from '@material-ui/icons/Description';

import Typography from 'components/Typography';

import styles from './styles.module.scss';

const NoResults = () => {
  return (
    <div className={styles.noResultsContainer}>
      <div className={styles.container}>
        <span></span>
        <div className={styles.center}>
          <div className={styles.wrap}>
            <div className={`${styles.box} ${styles.box2}`}>
              <DescriptionIcon fontSize="medium" />
              <DescriptionIcon fontSize="medium" />
              <DescriptionIcon fontSize="medium" />
              <DescriptionIcon fontSize="medium" />
              <DescriptionIcon fontSize="medium" />
              <DescriptionIcon fontSize="medium" />
            </div>
          </div>
        </div>
      </div>
      <Typography variant="H-TEXT-3" className={styles.message}>
        No Results Found
      </Typography>
    </div>
  );
};

export default NoResults;
