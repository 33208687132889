import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';

import Skeleton from '@material-ui/lab/Skeleton';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from 'chart.js';
import ChartjsPluginScrollBar from 'chartjs-plugin-scroll-bar';
import { isNull, isUndefined } from 'lodash';

import { MasteryLevelToColorMapper } from 'pages/MisconceptionsDashboard/constants';
import {
  hideTooltipWhenNotHoveringOnBarElementPlugin,
  splitLabelIntoMultiline
} from 'pages/MisconceptionsDashboard/utils';
import { getPlasUserStartegyName } from 'pages/Teachers/StrategiesOverview/utils';
import { trackMixpanelEvent } from 'utils/integrations/mixpanel';

import Typography from 'components/Typography';
import UserContext from 'components/UserContext';

import styles from './styles.module.scss';

ChartJS.register(ChartjsPluginScrollBar);

// Creates and appends a new tooltip element to the document body.
const createTooltipElement = () => {
  let tooltipElement = document.createElement('div');
  tooltipElement.id = 'chartjs-tooltip';
  tooltipElement.innerHTML = '<table></table>';
  document.body.appendChild(tooltipElement);

  return tooltipElement;
};

const externalTooltipHandler = ({ context, user, data }) => {
  // Retrieve an existing tooltip element or create a new one if not present.
  let tooltipElement =
    document.getElementById('chartjs-tooltip') || createTooltipElement();

  // Hide if no tooltip
  const tooltipModel = context.tooltip;
  if (tooltipModel.opacity === 0) {
    tooltipElement.style.opacity = 0;
    return;
  }

  // Set caret Position
  tooltipElement.classList.remove('above', 'below', 'no-transform');
  if (tooltipModel.yAlign) {
    tooltipElement.classList.add(tooltipModel.yAlign);
  } else {
    tooltipElement.classList.add('no-transform');
  }

  // Set Text
  if (tooltipModel.body) {
    const title = tooltipModel.title.join(' ');
    const bodyLine = tooltipModel.body.map((item) => item.lines.join(' '))[0];
    const bodyLineParts = bodyLine.split(': ');
    const descriptionsText = bodyLineParts[0];
    const studentCount = parseInt(bodyLineParts[1]);

    const strategyStudentCount = data.strategies.filter(
      (strategy) =>
        strategy.label.replaceAll(',', '') ===
        tooltipModel.title.join(' ').replaceAll(',', ' ')
    )[0].number_of_students;

    const studentCountPercentage = Math.round(
      (studentCount / strategyStudentCount) * 100
    );

    let innerHtml = '<tbody>';
    const span = `<span>${descriptionsText}: ${studentCount} (${studentCountPercentage.toFixed(
      0
    )}%)</span>`;
    innerHtml += `<tr><td>${span}</td></tr>`;
    innerHtml += '</tbody>';

    let tableRoot = tooltipElement.querySelector('table');
    tableRoot.innerHTML = innerHtml;
    trackMixpanelEvent(
      user.id,
      `[TrendsDashboard] User hovered over strategy ${title} with bar chart: ${descriptionsText}`,
      {
        strategy: title,
        description: descriptionsText,
        studentCount: studentCount
      }
    );
  }

  const position = context.chart.canvas.getBoundingClientRect();

  // Display, position, and set styles for font
  tooltipElement.style.opacity = 1;
  tooltipElement.style.position = 'absolute';
  tooltipElement.style.left =
    position.left + window.pageXOffset + tooltipModel.caretX + 'px';
  tooltipElement.style.top =
    position.top + window.pageYOffset + tooltipModel.caretY + 'px';

  tooltipElement.style.pointerEvents = 'none';
  tooltipElement.style.backgroundColor =
    tooltipModel.labelColors[0].backgroundColor;
  tooltipElement.style.color = '#FFF';
  tooltipElement.style.fontSize = '14px';
  tooltipElement.style.padding = '8px';
  tooltipElement.style.borderRadius = '4px';
};

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const getFontSize = () => {
  if (window.innerWidth >= 1024) {
    // Assuming 1024px and above is desktop
    return 16;
  }
  return 10;
};

const options = ({ user, data }) => ({
  responsive: true,
  maintainAspectRatio: false,
  indexAxis: 'x',
  onHover: (event, chartElement) => {
    event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
  },
  plugins: {
    legend: {
      position: 'bottom'
    },
    tooltip: {
      enabled: false,
      external: (context) => externalTooltipHandler({ context, user, data })
    },
    scrollBar: { enable: true, scrollType: 'Horizontal' }
  },
  scales: {
    // Configuration for the Y-axis text
    y: {
      beginAtZero: true,
      title: {
        display: true,
        text: 'Students',
        color: '#333966',
        font: {
          size: 20,
          family: 'Nunito',
          weight: '400',
          style: 'normal'
        }
      },
      ticks: {
        stepSize: 1
      }
    },
    x: {
      min: 0,
      max: 5,
      // Configuration for the X-axis text
      title: {
        display: true,
        color: '#333966',
        font: {
          size: 16,
          family: 'Nunito',
          weight: '400',
          style: 'normal'
        }
      },
      ticks: {
        color: '#333966',
        autoSkip: false,
        font: {
          size: getFontSize(),
          family: 'Nunito',
          weight: '400',
          style: 'normal'
        }
      }
    }
  }
});

const StrategiesBarChart = ({
  strategyData,
  isLoading,
  strategyExampleWorks,
  setStrategyExampleWorks
}) => {
  const [barChartData, setBarChartData] = useState(null);
  const user = useContext(UserContext);
  const chartRef = useRef(null);

  const studentCount = useMemo(() => {
    if (!isUndefined(strategyData) && !isLoading) {
      return strategyData.strategies
        .filter((strategy) => strategy.label === 'Overall response')[0]
        .data.reduce((acc, item) => acc + item.count, 0);
    }
  }, [strategyData, isLoading]);

  useEffect(() => {
    const resizeListener = () => {
      const chart = chartRef.current;
      if (chart) {
        chart.options.scales.x.ticks.font.size = getFontSize();
        chart.update();
      }
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  useEffect(() => {
    if (!isUndefined(strategyData) && !isLoading) {
      const groupedData = {};
      strategyData.strategies.forEach((strategy) => {
        strategy.data.forEach(({ mastery_level, count }) => {
          if (!groupedData[mastery_level]) {
            groupedData[mastery_level] = {
              label: user.user.is_PLAS_user
                ? getPlasUserStartegyName({ strategy: mastery_level })
                : mastery_level,
              backgroundColor: MasteryLevelToColorMapper[mastery_level],
              data: []
            };
          }
          groupedData[mastery_level].data.push(count);
        });
      });

      setBarChartData({
        labels: strategyData.strategies.map((item) =>
          splitLabelIntoMultiline(item.label, 15)
        ),
        datasets: Object.values(groupedData)
      });
      setStrategyExampleWorks(
        strategyData.strategies
          .map((item) => ({
            imageUrl: item.example_url,
            category: item.label
          }))
          .filter((item) => item.imageUrl !== null)
      );
    }
  }, [
    strategyData,
    isLoading,
    setStrategyExampleWorks,
    user.user.is_PLAS_user
  ]);

  if (
    isNull(barChartData) ||
    isNull(studentCount) ||
    isNull(strategyExampleWorks) ||
    isLoading
  ) {
    return (
      <div className={styles.centeredSkeletonContainer}>
        <Skeleton height={250} width={700} variant="rect" />
      </div>
    );
  }

  return (
    <div className={styles.centeredContainer}>
      <Typography variant="H-TEXT-1" className={styles.barTitle}>
        Total: {studentCount} Students
      </Typography>
      <div className={styles.barChartContainer}>
        <Bar
          ref={chartRef}
          options={options({ user, data: strategyData })}
          data={barChartData}
          plugins={[hideTooltipWhenNotHoveringOnBarElementPlugin]}
        />
      </div>
    </div>
  );
};

export default StrategiesBarChart;
