import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import _ from 'lodash';
import { useLessonsForOrganization } from 'sdk';

import { TopBarSelect } from 'pages/Teachers/shared';
import { parseParams, stringifyParams } from 'utils/urls';

const LessonSelectDropdown = ({
  selectedOrganization,
  selectedGrade,
  selectedLesson,
  selectedUnit,
  setSelectedLesson
}) => {
  const { data: lessons, isLoading } = useLessonsForOrganization({
    organizationId: selectedOrganization.id,
    params: {
      grade: selectedGrade.name,
      unit: selectedUnit.name
    }
  });

  const history = useHistory();
  const urlParams = parseParams(history.location.search);
  const lessonNumber = _.get(urlParams, 'lesson');

  useEffect(() => {
    if (!isLoading) {
      if (!_.isEmpty(lessons)) {
        const lesson = lessons.find((lesson) => lesson.name === lessonNumber);
        if (lesson) {
          setSelectedLesson(lesson);
        } else {
          const defaultLesson = lessons.find((lesson) => lesson.is_default);
          setSelectedLesson(defaultLesson);

          urlParams.lesson = defaultLesson.name;
          history.replace({ search: stringifyParams(urlParams) });
        }
      } else {
        setSelectedLesson(null);

        delete urlParams.lesson;
        history.replace({ search: stringifyParams(urlParams) });
      }
    }
  }, [lessons, setSelectedLesson, lessonNumber, history, urlParams, isLoading]);

  const selectLesson = useCallback(
    (newLesson) => {
      setSelectedLesson(newLesson);

      urlParams.lesson = newLesson.name;
      history.replace({ search: stringifyParams(urlParams) });
    },
    [history, urlParams, setSelectedLesson]
  );

  if (_.isEmpty(lessons)) return <></>;
  return (
    <TopBarSelect
      value={selectedLesson}
      renderValue={(lesson) => `Lesson ${_.get(lesson, 'name')}`}
      onChange={selectLesson}
      options={lessons.map((lesson) => ({
        value: lesson,
        label: lesson.name
      }))}
    />
  );
};

export default LessonSelectDropdown;
